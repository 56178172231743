import React, { Component, Fragment, Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { showMenu } from "../Menu/menu.actions";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import CustomMap from "../../components/CustomMap";
import styles from "./styles.module.scss";

import {
  chgWorkStatus,
  resetOrders,
  resetDriverOffline,
  clearError,
  checkPushOrderAccepted,
  checkPushOrderAcceptedError,
} from "./orders.actions";
import { getDriverInfo, getRegInfo, sendCoordsToRpc } from "../RegistrationPage/registration.actions";
import {
  showModalCars,
  getAvtos,
  setCurrentCarId,
  selectedCarStatus,
  setCarId,
  setEditCarId,
  setDefaultExpressCar,
  shouldShowExpressCarWindow,
  disableExpressCarWindow,
} from "../Cars/cars.actions";
import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import { resetExitOrder, currentOrderInfo, resetNoOrder } from "../Order/order.actions";

import { t } from "i18next";
import BottomMenu from "../BottomMenu";
import { setFirstRegistration, updRegistrationStep } from "../RegistrationPage/registration.actions";
import ModalOrder from "../ModalOrder";

import { updateRoute } from "../../components/CustomMap/helpers/updateRoute";
import { clearIncomeOrder } from "src/services/clearIncomeOrder";
import { waitingLS } from "src/services/waitingLS";

import "./orders.scss";
import OrderControls from "./fragment/OrderControls";
import { getBrands, getClasses, getColors, getModels, getPreferences } from "../AddCar/addcar.actions";
import { classNames } from "src/shared/lib/classNames/classNames";
import FinishWorkBtn from "./fragment/FinishWorkBtn";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import withNavigation from "src/hoc/NavigateForClass";
import Loader from "../Loader";
import { changeWorkStatus } from "./helpers/changeWorkStatus";
import { fetchEtherOrders } from "src/pages/EtherOrdersPage/store/services/fetchEtherOrders";
import ExpressCar from "src/components/ExpressCar";
import { onContentLoaded, setWebKitWorkState } from "src/shared/funcForMobile";
import { updateCoords } from "src/shared/api/updateCoords";
import { OrderCancellationReasons } from "src/shared/const/orders";
import { withRTKQuery } from "./hoc/withRTKQuery";
import { useState } from "react";
const FilterByPolygons = React.lazy(() => import("./components/FilterByPolygons"));

const SetManualCoords = lazy(() => import("src/feature/SetManualCoords"));

class Orders extends Component {
  state = {
    mainAlertText: "",
    readyForOrder: localStorage.getItem("readyForOrder"),
    isOnline: JSON.parse(localStorage.getItem("isOnline")) || false,
    mapLoaded: false,
    coordsDriver: null,
    polylinePositions: null,
    mapPoints: null,
    disabledWorkBtn: false,
    resetZoom: false,
    orderDuration: 0,
    selectedPolygons: [],
  };

  async componentDidMount() {
    const { canceledOrder } = this.props;

    const acceptDriverCar = JSON.parse(localStorage.getItem("acceptDriverCar"));

    this.props.sendCoordsToRpc();
    // show modal on cancel order
    if (canceledOrder) {
      // The canceledOrder condition is met, update the state
      this.handleCanceledOrder();
    }

    const accessToken = localStorage.getItem("accessToken");
    const orderStage = localStorage.getItem("orderStage");

    // set driver coords, and listen them
    this.coordsCheckInterval = setInterval(async () => {
      //request new polyline route if driver move on 35m from new newPolyPoints

      const coordsDriver = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : null;

      if (coordsDriver && JSON.stringify(coordsDriver) !== JSON.stringify(this.state.coordsDriver)) {
        this.setState({
          coordsDriver,
        });
      }
    }, 100);

    const coordsDriver = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : null;
    if (coordsDriver) {
      this.setState({ coordsDriver });
    }

    if (this.props.isFirstRegistration === true) {
      this.props.setFirstRegistration(false);
    }

    if (orderStage) {
      localStorage.removeItem("orderStage");
    }

    if (accessToken) {
      localStorage.setItem("isOnline", this.props.atwork || false);
    }

    // check balance in first render
    if (this.props.atwork) {
      await this.checkEmptyBalance();
    }

    if (!acceptDriverCar) {
      this.props.shouldShowExpressCarWindow();
    }

    this.props.getRegInfo();
    this.props.currentOrderInfo(this.props.navigate);
    this.props.getDriverInfo();
    this.props.getAvtos();
    this.props.getPreferences();

    if (this.props.atwork) {
      this.props.fetchEtherOrders();
    }

    localStorage.setItem("readyForOrder", true); //  fixOrderInfo
    localStorage.removeItem("timeLeft");
    localStorage.removeItem("distanceLeft");
  }

  async componentDidUpdate(prevProps, prevState) {
    // update current coords for driver for profile
    if (prevState.coordsDriver === null && this.state.coordsDriver) {
      const { latitude, longitude } = this.state.coordsDriver;
      updateCoords({ lat: latitude, lon: longitude });
    }

    // inform app about finish map load
    if (prevState.mapLoaded !== this.state.mapLoaded && this.state.mapLoaded) {
      onContentLoaded();
    }

    // Check your conditions and navigate accordingly
    const { acceptOrder, currentOrderStatus, atwork, socketConnect } = this.props;

    // on accept order redirect on order page
    if (acceptOrder || (currentOrderStatus && atwork && socketConnect)) {
      this.props.navigate("/order");
    }

    const isOnline = JSON.parse(localStorage.getItem("isOnline"));

    if (
      JSON.stringify(prevProps.order.destinationPoints) !== JSON.stringify(this.props.order.destinationPoints)
    ) {
      this.getRoutePolyline(this.props.order.destinationPoints);
    }

    // check if balance change after close order
    if (
      (prevProps.missedOrder !== this.props.missedOrder || prevProps.closeOrder !== this.props.closeOrder) &&
      this.props.atwork
    ) {
      await this.checkEmptyBalance();
    }

    if (isOnline !== this.state.isOnline) {
      this.setState({
        isOnline,
      });
      setWebKitWorkState(isOnline);
    }

    if (
      prevProps.pushOrderAcceptedError !== this.props.pushOrderAcceptedError &&
      this.props.pushOrderAcceptedError === true
    ) {
      this.setState({
        mainAlertText: t("order_is_not_relevant"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.resetNoOrder();
    }

    if (
      prevProps.pushOrderAccepted !== this.props.pushOrderAccepted &&
      this.props.pushOrderAccepted === true &&
      !this.props.noOrder
    ) {
      this.setState({
        mainAlertText: t("order_processing_in_progress"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);

      this.props.currentOrderInfo(this.props.location, null, null, true);
    }

    if (prevProps.isErrorOrder !== this.props.isErrorOrder) {
      this.setState({
        mainAlertText: t("failed_process_order"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    }
  }

  componentWillUnmount() {
    clearInterval(this.coordsCheckInterval);
  }

  Menu = () => {
    let showM = !this.props.showmenu;
    this.props.showMenu(showM);
  };

  checkEmptyBalance = async () => {
    await this.props.getDriverInfo();

    if (this.props.profInfo.balance >= 0) {
      return false;
    }

    // remove socket connection
    this.props.chgWorkStatus({ status: false });
    this.setState({
      mainAlertText: t("insufficient_funds_on_the_balance"),
      disabledWorkBtn: false,
    });

    this.props.showModalAlert(true);
    localStorage.removeItem("socket_initialized");
    return true;
  };

  chgWorkStat = async () => {
    clearIncomeOrder();

    if (!this.props.atwork) {
      await this.props.getAvtos();
    }

    this.setState({
      disabledWorkBtn: true,
    });

    if (this.props.selectedCarId === null && !this.props.atwork) {
      this.setState({
        mainAlertText: t("no_default_car"),
        disabledWorkBtn: false,
      });

      this.props.showModalAlert(true);

      return false;
    }

    const emptyBalance = await this.checkEmptyBalance();

    if (emptyBalance) {
      return;
    }

    // check date, do request on change work status
    await changeWorkStatus({
      selectedCar: this.props.selectedCar,
      atwork: this.props.atwork,
      regInfo: this.props.regInfo,
      chgWorkStatus: this.props.chgWorkStatus,
      showModalCars: this.props.showModalCars,
      destinationDistricts: this.state.selectedPolygons,
      showModalAlert: () => {
        this.setState({
          mainAlertText: t("current_car_has_not_passed_TO"),
        });
        this.props.showModalAlert(true);
      },
    });

    this.setState({
      disabledWorkBtn: false,
    });
  };

  tryClearError = () => {
    this.props.clearError();
  };

  handleBeforeRadiusChange = () => {
    if (this.props.atwork) {
      this.setState({
        mainAlertText: t("before_changing_radius_finalize_your_work"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);

      return true;
    }

    return false;
  };

  setMapLoaded = (condition) => {
    this.setState({ mapLoaded: condition });
  };

  // create order path before accept
  getRoutePolyline = async (mapPoints) => {
    if (!mapPoints) {
      return;
    }

    let orderDuration = 0;

    const positions = await Promise.all(
      mapPoints.flatMap(async (point, index) => {
        const prevItem = mapPoints[index - 1];

        if (!prevItem) {
          return [[point.lat, point.lon]];
        }

        const { formattedCoordinates, response } = await updateRoute({
          startPos: [prevItem.lon, prevItem.lat],
          endPos: [point.lon, point.lat],
        });

        const duration = response.data.features[0].properties.summary.duration;
        orderDuration = orderDuration + duration;
        return formattedCoordinates;
      }),
    );

    waitingLS.setCurrentTimeTrip(orderDuration);

    // connect all new polyline points arrays to one
    let positionsToOneArr = positions.reduce((accumulator, currentArray) => {
      return accumulator.concat(currentArray);
    }, []);

    this.setState({
      polylinePositions: positionsToOneArr,
      zoomFullRoute: true,
      mapPoints,
      orderDuration,
    });
  };

  // On click modal remove order path
  onClickModalOrder = () => {
    this.setState({
      polylinePositions: null,
      mapPoints: null,
      resetZoom: true,
    });
  };

  // zoom to full route view
  setZoomFullRoute = (value) => {
    this.setState({
      zoomFullRoute: value,
    });
  };

  editCar = () => {
    const { setCarId, setEditCarId, cars } = this.props;

    const findActiveCar = cars.find((car) => car.isDefault);

    if (findActiveCar.isDefault) {
      setCarId(findActiveCar.id);
      setEditCarId(findActiveCar.id);
    }
  };

  handleCanceledOrder = () => {
    const { showMAlert, resetExitOrder, showModalAlert, rejectedBy } = this.props;

    const mainAlertText =
      rejectedBy === OrderCancellationReasons.BY_DRIVER ? t("order_cancelled") : t("customer_canceled_trip");

    this.setState({ mainAlertText });

    // Reset the exit order, clear income order, etc.
    resetExitOrder();
    clearIncomeOrder();

    // Show or hide the modal alert
    showModalAlert(!showMAlert);
  };

  setSelectedPolygons = (polygon) => {
    const prevSelectedPolygons = this.state.selectedPolygons;

    if (prevSelectedPolygons.includes(polygon.name)) {
      const filteredPolygons = prevSelectedPolygons.filter(
        (prevSelectedPolygon) => prevSelectedPolygon !== polygon.name,
      );
      return this.setState({ selectedPolygons: filteredPolygons });
    }

    const newSelectedPolygons = [...prevSelectedPolygons, polygon.name];

    if (newSelectedPolygons.length >= 3) {
      return newSelectedPolygons.filter((item, index) => index !== 0);
    }

    this.setState({ selectedPolygons: newSelectedPolygons });
  };

  render() {
    const {
      atwork,
      missedOrder,
      incomingOrderStatus,
      currentOrderStatus,
      showMAlert,
      profileActivated,
      canceledOrder,
      driverOffline,
      errorStatus,
      order,
      noOrder,
      driverCancelOrder,
    } = this.props;

    const timeForOrder = JSON.parse(localStorage.getItem("timeForOrder"));
    const isPolygonsFromRtkHok = this.props.polygonsFromRtkHok && this.props.polygonsFromRtkHok?.length > 0;
    const isDailyAttemptFromRtkHok =
      this.props.dailyAttemptFromRtkHok == 0 || this.props.dailyAttemptFromRtkHok;
    const showFilterByPolygons = this.state.coordsDriver && isPolygonsFromRtkHok && isDailyAttemptFromRtkHok;

    const orderStage = localStorage.getItem("orderStage");
    const isOpenOrderModal =
      (incomingOrderStatus || missedOrder || driverCancelOrder) &&
      !canceledOrder &&
      !noOrder &&
      !orderStage &&
      this.state.mapLoaded;

    if (!profileActivated) {
      this.setState({
        mainAlertText: t("driver_not_activated"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.resetOrders();
    }

    if (driverOffline) {
      this.setState({
        mainAlertText: driverOffline,
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.resetDriverOffline();
    }

    if (currentOrderStatus) {
      localStorage.setItem("readyForOrder", false); // fixOrderInfo
      localStorage.setItem("selectedCarId", order.carId);

      if (!atwork) {
        let getWorkData = {
          status: true,
          driverId: this.props.regInfo.id,
          carId: order.carId,
        };

        this.props.chgWorkStatus(getWorkData);
      }
    }

    if (noOrder) {
      this.setState({
        mainAlertText: t("order_is_not_relevant"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      this.props.resetNoOrder();
    }

    // dynamic layers
    const roundsAdapter = () => {
      const pointsDynamicPriceData = this.props.dynamicPriceFromRtkHok;
      if (pointsDynamicPriceData && pointsDynamicPriceData.radius && pointsDynamicPriceData.areas) {
        return {
          ...pointsDynamicPriceData,
          areas: new Map(pointsDynamicPriceData.areas.map((area) => [area.id, area])),
        };
      }

      return null;
    };

    return (
      <>
        {!this.props.showExpressCarWindow && (
          <ExpressCar
            brands={this.props.brands}
            cars={this.props.cars}
            classes={this.props.classes}
            colors={this.props.colors}
            disableExpressCarWindow={this.props.disableExpressCarWindow}
            getAvtos={this.props.getAvtos}
            getBrands={this.props.getBrands}
            getClasses={this.props.getClasses}
            getColors={this.props.getColors}
            getModels={this.props.getModels}
            models={this.props.models}
            setDefaultExpressCar={this.props.setDefaultExpressCar}
            shouldShowExpressCarWindow={this.props.shouldShowExpressCarWindow}
          />
        )}

        {/* if not gps signal set manual cords for car pos */}
        <SetManualCoords
          coordsDriver={
            this.state.coordsDriver
              ? [this.state.coordsDriver.longitude, this.state.coordsDriver.latitude]
              : null
          }
        />
        {(!this.state.mapLoaded || !this.state.coordsDriver) && (
          <Loader loaderText={[t("location_data_needed_for_map_view"), t("please_wait")]} showMenuSpace />
        )}
        <ModalAlert mainAlertText={this.state.mainAlertText} />
        <div className="container-fluid p-0 m-0">
          <div className="row m-0">
            <div className="orders-section col-sm-12 p-0">
              {!this.state.isOnline ? (
                <button
                  className={classNames("work-btn", { "disabled-btn": this.state.disabledWorkBtn }, [
                    "offline",
                  ])}
                  disabled={this.state.disabledWorkBtn}
                  onClick={this.chgWorkStat}
                >
                  {t("start_job")}
                </button>
              ) : (
                <FinishWorkBtn finishWork={this.chgWorkStat} />
              )}

              {this.state.coordsDriver && (
                <CustomMap
                  rounds={roundsAdapter()}
                  coordsDriver={[this.state.coordsDriver.longitude, this.state.coordsDriver.latitude]}
                  disabledFunctions={{
                    disableSeparateRoute: isOpenOrderModal,
                    disableButtons: isOpenOrderModal,
                    disableGlueCar: isOpenOrderModal,
                    disabledSpeed: true,
                    disabledNavigation: isOpenOrderModal,
                    disableInteractive: false,
                    disabled3DView: false,
                  }}
                  hiddenButtons={isOpenOrderModal}
                  mapLoaded={this.state.mapLoaded}
                  mapPoints={this.state.mapPoints}
                  polylinePositions={this.state.polylinePositions}
                  resetZoom={this.state.resetZoom}
                  setMapLoaded={this.setMapLoaded}
                  setResetZoom={() =>
                    this.setState({
                      resetZoom: false,
                    })
                  }
                  setZoomFullRoute={this.setZoomFullRoute}
                  speed={this.state.coordsDriver.speed}
                  zoomFullRoute={this.state.zoomFullRoute}
                />
              )}
              {isOpenOrderModal ? (
                <ModalOrder
                  onClickModalOrder={this.onClickModalOrder}
                  orderDuration={this.state.orderDuration}
                />
              ) : (
                <>
                  <div className="status-label-wrap">
                    <MobileTopBarHeight />
                    <div className={this.state.isOnline ? "status-label workon" : "status-label workoff"}>
                      {this.state.isOnline ? t("working") : t("not_working")}
                    </div>
                  </div>

                  {!this.props.atwork && (
                    <>
                      {showFilterByPolygons && (
                        <div className={styles.ordersFilterByPolygons}>
                          <FilterByPolygons
                            coordsDriver={[
                              this.state.coordsDriver.longitude,
                              this.state.coordsDriver.latitude,
                            ]}
                            dailyAttempt={this.props.dailyAttemptFromRtkHok}
                            polygons={this.props.polygonsFromRtkHok}
                            selectedPolygons={this.state.selectedPolygons}
                            setSelectedPolygons={this.setSelectedPolygons}
                          />
                        </div>
                      )}
                      <div className="controls-block">
                        <OrderControls
                          editCar={this.editCar}
                          handleBeforeRadiusChange={this.handleBeforeRadiusChange}
                        />
                      </div>
                    </>
                  )}
                  <BottomMenu />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showmenu: state.menu.showmenu,
    atwork: state.orders.workStatus,
    orderShowRequestBody: state.orders.orderShowRequestBody,
    pushOrderAccepted: state.orders.pushOrderAccepted,
    pushOrderAcceptedError: state.orders.pushOrderAcceptedError,
    regInfo: state.registration.driver,
    rejectedBy: state.order.rejectedBy,
    showModalStatus: state.cars.showModalStatus,
    selectCarStatus: state.cars.selectCarStatus,
    selectedCar: state.cars.selectedCar,
    incomingOrderStatus: state.order.incomingOrderStatus,
    missedOrder: state.order.missedOrder,
    acceptOrder: state.order.acceptOrder,
    currentOrderStatus: state.order.currentOrderStatus,
    cars: state.cars.avtos,
    showMAlert: state.modalAlert.showMAlert,
    profileActivated: state.orders.profileActivated,
    canceledOrder: state.order.canceledOrder,
    closeOrder: state.order.closeOrder,
    driverCancelOrder: state.order.driverCancelOrder,
    socketConnect: state.orders.socketConnect,
    driverOffline: state.orders.driverOffline,
    profInfo: state.registration.profinfo,
    errorStatus: state.orders.errorStatus,
    errorMsg: state.orders.errorMsg,
    isErrorOrder: state.order.isErrorOrder,
    order: state.order.order,
    noOrder: state.order.noOrder,
    selectedCarId: state.cars.selectedCarId,
    isFirstRegistration: state.registration.isFirstRegistration,
    brands: state.addcar.brands,
    models: state.addcar.models,
    colors: state.addcar.colors,
    classes: state.addcar.classes,
    showExpressCarWindow: state.cars.showExpressCarWindow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chgWorkStatus: (getWorkData) => dispatch(chgWorkStatus(getWorkData)),
    showMenu: (showM) => dispatch(showMenu(showM)),
    getDriverInfo: (isFinancialPage) => dispatch(getDriverInfo(isFinancialPage)),
    getRegInfo: () => dispatch(getRegInfo()),
    showModalCars: (status) => dispatch(showModalCars(status)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    resetOrders: () => dispatch(resetOrders()),
    resetExitOrder: () => dispatch(resetExitOrder()),
    resetDriverOffline: () => dispatch(resetDriverOffline()),
    clearError: () => dispatch(clearError()),
    currentOrderInfo: (routsHistory, statusId, nextStatusId, retryOnError) =>
      dispatch(currentOrderInfo(routsHistory, statusId, nextStatusId, retryOnError)),
    resetNoOrder: () => dispatch(resetNoOrder()),
    checkPushOrderAccepted: () => dispatch(checkPushOrderAccepted()),
    checkPushOrderAcceptedError: () => dispatch(checkPushOrderAcceptedError()),
    setCurrentCarId: (id) => dispatch(setCurrentCarId(id)),
    selectedCarStatus: (selectInfo) => dispatch(selectedCarStatus(selectInfo)),
    // updAccessToken: depFuncs => dispatch(updAccessToken(depFuncs)),
    updRegistrationStep: (registrationStep) => dispatch(updRegistrationStep(registrationStep)),
    setFirstRegistration: (isFirstRegistration) => dispatch(setFirstRegistration(isFirstRegistration)),
    setCarId: (id) => dispatch(setCarId(id)),
    setEditCarId: (id) => dispatch(setEditCarId(id)),
    fetchEtherOrders: (data) => dispatch(fetchEtherOrders(data)),
    sendCoordsToRpc: () => dispatch(sendCoordsToRpc()),
    setDefaultExpressCar: (carInfo) => dispatch(setDefaultExpressCar(carInfo)),
    getPreferences: () => dispatch(getPreferences()),
    getAvtos: () => dispatch(getAvtos()),
    getModels: () => dispatch(getModels()),
    getBrands: () => dispatch(getBrands()),
    getColors: () => dispatch(getColors()),
    getClasses: () => dispatch(getClasses()),
    shouldShowExpressCarWindow: () => dispatch(shouldShowExpressCarWindow()),
    disableExpressCarWindow: () => dispatch(disableExpressCarWindow()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(withRTKQuery(Orders)));
