import React, { useEffect, useState } from "react";
import createMarker from "../helpers/createMarker";

const useMarkerInCenter = ({
  map,
  mapLoaded,
  centerPointMarkerRef,
  coordsDriver,
  disabledMarkerInCenter,
  setMarkerCenterCoords,
}) => {
  // console.log("map.current", map.current);

  useEffect(() => {
    if (!map.current || !mapLoaded || disabledMarkerInCenter) {
      return;
    }
    // Create custom marker element
    const markerElement = document.createElement("div");
    markerElement.className = "pickupPointMarker";

    if (!centerPointMarkerRef.current) {
      createMarker({
        map,
        markerElement,
        position: coordsDriver,
        markerRef: centerPointMarkerRef,
      });
    }

    // update marker pos on map
    const updateMarkerPosition = () => {
      const center = map.current.getCenter();
      centerPointMarkerRef.current.setLngLat([center.lng, center.lat]);
    };

    // update coords when finish marker move
    const setCoords = () => {
      const center = map.current.getCenter();
      setMarkerCenterCoords([center.lng, center.lat]);
      centerPointMarkerRef.current.removeClassName("animateMarker");
    };

    map.current.on("move", updateMarkerPosition);
    map.current.on("movestart", () => centerPointMarkerRef.current.addClassName("animateMarker"));
    map.current.on("moveend", setCoords);

    return () => {
      if (map.current) {
        map.current.off("move", updateMarkerPosition);
        map.current.off("movestart", () => centerPointMarkerRef.current.addClassName("animateMarker"));
        map.current.off("moveend", setCoords);
      }
    };
  }, [centerPointMarkerRef, coordsDriver, disabledMarkerInCenter, map, mapLoaded, setMarkerCenterCoords]);
};

export default useMarkerInCenter;
