import React from "react";
import TextField from "@mui/material/TextField";

import styles from "./styles.module.scss";

const DefaultInput = ({ customClassName, ...otherProps }) => {
  return <TextField className={`${styles.defaultInput} ${customClassName}`} {...otherProps} />;
};

export default DefaultInput;
