import axios from "axios";
import { osmMapPolyline } from "src/config";

class RouteService {
	constructor() {
		this.fullDistance = 0;
		this.segmentDistances = [];
		this.formattedCoordinates = [];
	}

	async fetchRoute(start, end) {
		const routeUrl = `${osmMapPolyline}?start=${start}&end=${end}`;
		const response = await axios.get(routeUrl);

		return response.data;
	}

	async createRoutes(startPoints, endPoints) {
		try {
			const responses = await Promise.all(
				startPoints.map(async (start, index) => {
					const routeData = await this.fetchRoute(start, endPoints[index]);
					return routeData;
				}),
			);

			responses.forEach((response) => {
				if (!response) {
					return;
				}

				const reverseCoordinates =
					response.features[0].geometry.coordinates.map((item) => [
						item[1],
						item[0],
					]);

				this.segmentDistances.push(
					response.features[0].properties.segments[0].distance,
				);

				this.fullDistance +=
					response.features[0].properties.segments[0].distance;

				if (reverseCoordinates) {
					this.formattedCoordinates.push(...reverseCoordinates);
				}
			});

			return {
				formattedCoordinates: this.formattedCoordinates || [],
				segmentDistances: this.segmentDistances || [], // Дистанция до каждой точки
				fullDistance: this.fullDistance || 0,
			};
		} catch (error) {
			console.log("Error createRoutes", error);
		}
	}

	createPoints(geoPoints) {
		const start = [];
		const end = [];

		for (let i = 1; i < geoPoints.length; i++) {
			const current = geoPoints[i];
			const previous = geoPoints[i - 1];

			if (current.lat && current.lon && previous.lat && previous.lon) {
				start.push(`${previous.lon},${previous.lat}`);
				end.push(`${current.lon},${current.lat}`);
			}
		}

		return { start, end };
	}
}

export default RouteService;
