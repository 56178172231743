import {
  REG_INFO,
  REG_STATUS,
  PROF_INFO,
  TRY_REG_INFO,
  UPD_PROFILE_STATUS,
  ERROR_MSG,
  CLEAR_ERROR,
  CLEAR_REG,
  PHONE_IN_USE,
  CLEAR_PHONE_IN_USE,
  LOGIN_IN_USE,
  CLEAR_LOGIN_IN_USE,
  UPD_PHOTO_STATUS,
  UPD_LICENSE_STATUS,
  SET_FIRST_REGISTRATION,
  PROF_LOADING,

  // RPC
  POST_PRC_INFO_SUCCESS,
  POST_RPC_INFO_LOADING,
  POST_PRC_INFO_ERROR,
  GET_DRIVER_COORDS_ERROR,
  CLEAR_RPC_ERROR,
  SET_PROFILE_LANGUAGE,
} from "./registration.actions.js";

const initialState = {
  driver: {},
  regStatus: false,
  phone: "",
  profinfo: {},
  tryRegInfo: {},
  updProfileStatus: false,
  errorStatus: false,
  errorMsg: "",
  phoneInUse: false,
  loginInUse: false,
  updPhotoStatus: false,
  updLicenseStatus: false,
  isFirstRegistration: false,
  profLoading: false,

  //RPC
  rpcLoaded: false,
  rpcCountryInfo: {},
  rpcError: false,
  getCoordsError: false,
};

function registration(state = initialState, action) {
  switch (action.type) {
    case REG_INFO:
      return { ...state, driver: action.payload };
    case PROF_LOADING:
      return { ...state, profLoading: true };
    case REG_STATUS:
      return { ...state, regStatus: action.payload };
    case SET_FIRST_REGISTRATION:
      return { ...state, isFirstRegistration: action.payload };
    case TRY_REG_INFO:
      return {
        ...state,
        phone: action.payload.phone,
        tryRegInfo: action.payload,
      };
    case PROF_INFO:
      return { ...state, profinfo: action.payload, profLoading: false };
    case UPD_PROFILE_STATUS:
      return { ...state, updProfileStatus: action.payload };
    case UPD_PHOTO_STATUS:
      return { ...state, updPhotoStatus: action.payload };
    case UPD_LICENSE_STATUS:
      return { ...state, updLicenseStatus: action.payload };
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true };
    case CLEAR_ERROR:
      return { ...state, errorMsg: "", errorStatus: false };
    case CLEAR_REG:
      return {
        ...state,
        driver: {},
        regStatus: false,
        phone: "",
        profinfo: {},
        tryRegInfo: {},
        updProfileStatus: false,
        errorMsg: "",
        errorStatus: false,
      };
    case PHONE_IN_USE:
      return { ...state, phoneInUse: true };
    case CLEAR_PHONE_IN_USE:
      return { ...state, phoneInUse: false };
    case LOGIN_IN_USE:
      return { ...state, loginInUse: true };
    case CLEAR_LOGIN_IN_USE:
      return { ...state, loginInUse: false };
    case POST_RPC_INFO_LOADING: {
      return {
        ...state,
        rpcLoaded: true,
      };
    }
    case SET_PROFILE_LANGUAGE: {
      return {
        ...state,
        profinfo: { ...state.profinfo, language: action.payload },
      };
    }
    case POST_PRC_INFO_SUCCESS: {
      return {
        ...state,
        rpcLoaded: false,
        rpcCountryInfo: action.payload,
      };
    }
    case POST_PRC_INFO_ERROR: {
      return {
        ...state,
        rpcLoaded: false,
        rpcError: action.payload,
      };
    }
    case GET_DRIVER_COORDS_ERROR: {
      return {
        ...state,
        rpcLoaded: false,
        getCoordsError: true,
      };
    }
    case CLEAR_RPC_ERROR: {
      return {
        ...state,
        rpcError: false,
        getCoordsError: false,
      };
    }
    default:
      return { ...state };
  }
}
export default registration;
