import React, { memo } from "react";
import { Snackbar, Alert } from "@mui/material";

const CustomSnackbar = ({
	open,
	onClose,
	autoHideDuration,
	severity = "info",
	message,
}) => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			autoHideDuration={autoHideDuration}
			onClose={onClose}
			open={open}
		>
			<Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default memo(CustomSnackbar);
