export const CARMA_TITLE = "@ LT 2023 - Alpha Version";
export const CARMA_VERSION = "v2.0.0.0";
export const MAX_IMAGE_SIZE_MB = 15;
export const CHECK_COORDS_IN_LOCAL_STORAGE_INTERVAL_MS = 1000;

export const CURRENT_ORDER_INFO_REQUEST_INTERVAL_MS = 50;
export const CURRENT_ORDER_INFO_REQUEST_MAX_ATTEMPTS = 5;

export const CURRENT_ORDER_INFO_RETRY_INTERVAL_MS = 1000; // если currentOrderInfo падает с ошибкой (отключился интернте и т.д)
export const CURRENT_ORDER_INFO_MAX_RETRY_COUNT = 6; // если currentOrderInfo падает с ошибкой (отключился интернте и т.д)

export const CURRENT_ORDER_STATUS_CHECK_INTERVAL_MS = 50; // Сверяем статус currentOrderInfo с текущим статусом заказа
export const CURRENT_ORDER_STATUS_CHECK_MAX_ATTEMPTS = 5; // Сверяем статус currentOrderInfo с текущим статусом заказа

export const ORDER_REQUEST_RETRY_DELAY_MS = 7000;

export const WITHDRAWAL_COMMISION_PERCENTAGE = 1;

export const DRIVER_ONLINE_CHECK_INTERVAL_MS = 60000;

// For payment page
export const maxLimitPaymentCards = 10;
export const maxAttempts = 40;
export const pollingInterval = 500;
