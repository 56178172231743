import React, { useEffect, useState } from "react";
import { t } from "i18next";
import moment from "moment";

import ModalNew from "src/pages/Modals/ModalNew";

import { ReactComponent as Close } from "src/images/close.svg";
import { ReactComponent as Star } from "src/images/star.svg";
import noImg from "src/media/noimg.jpg";
import { FaStar } from "react-icons/fa";

import styles from "./styles.module.scss";
import { Button } from "src/shared/ui/Buttons/DefautlButton/button";
import { BlackButton } from "src/shared/ui/Buttons/BlackButton";
import { connect } from "react-redux";
import { checkPushOrderAccepted } from "src/pages/Orders/orders.actions";
import { setClientRating } from "src/pages/Finishedorder/finishedorder.actions";
import { clearOrder } from "../../order.actions";

const ratingStars = [1, 2, 3, 4, 5];

const ReviewOrder = (props) => {
  const { order, checkPushOrderAccepted, setClientRating, clearOrder } = props;
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const currentTime = moment().format("DD MMM YYYY, HH:mm");

  const clearOnSend = () => {
    localStorage.setItem("readyForOrder", true);
    clearOrder();
  };

  const sendReview = () => {
    localStorage.removeItem("orderStage");
    const clientRatingData = {
      orderId: order.orderId,
      rating: rating || 5,
      comment,
    };
    setClientRating(clientRatingData);
    clearOnSend(clientRatingData);
  };

  const stars = ratingStars.map((star, index) => {
    return (
      <li
        key={index}
        className={styles.ratingItem}
        onClick={() => setRating(star)}
      >
        <Star
          className={`${styles.star} ${
            star <= rating ? styles.starBlue : ""
          }`}
        />
      </li>
    );
  });

  const ReviewOrderComponent = (
    <div className={styles.reviewOrderWrap}>
      <Close className={styles.closeIcon} onClick={clearOnSend} />
      <h2>{t("rate_trip")}</h2>
      <span className={styles.reviewOrderTime}>{currentTime}</span>
      <img alt="User Avatar" src={order.clientPhoto ? order.clientPhoto : noImg} />
      <span className={styles.customerName}>{order.clientFullName}</span>
      <ul className={styles.ratingWrap}>{stars}</ul>
      <textarea
        onChange={(event) => setComment(event.target.value)}
        placeholder={`${t("your_comment")}...`}
        rows="4"
        value={comment}
      />
      <BlackButton onClick={sendReview}>{t("send")}</BlackButton>
    </div>
  );

  useEffect(() => {
    localStorage.setItem("readyForOrder", true);
    localStorage.removeItem("pushOrderAccepted");
    checkPushOrderAccepted();
  }, []);
  return <ModalNew customInnerComponent={ReviewOrderComponent} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setClientRating: (clientRatingData) => dispatch(setClientRating(clientRatingData)),
    checkPushOrderAccepted: () => dispatch(checkPushOrderAccepted()),
    clearOrder: () => dispatch(clearOrder()),
  };
};

export default connect(null, mapDispatchToProps)(ReviewOrder);
