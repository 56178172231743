import { axiosInstanceWithToken } from "src/axios.config";
import { apiReverseGeocode } from "src/config";
import { getLanguageCountry } from "src/shared/constants";

// point {lat: 50.3689975283996, lon: 30.45977962831296}, country: "UA"
export const fetchGeocodeFromCoords = async ({ point, languageName }) => {
  let requestData = {
    point,
    country: languageName,
    language: getLanguageCountry(languageName),
  };

  if (languageName) {
    requestData = { ...requestData, country: languageName };
  }

  try {
    const response = await axiosInstanceWithToken.post(apiReverseGeocode, requestData);
    return response.data;
  } catch (error) {
    console.log("Error in fetchGeocodeFromCoords:", error);
    return []; // Return an empty array or handle the error as needed
  }
};
