const getCenterAndZoom = (mapPoints, map, padding) => {
	if (!mapPoints || !map) {
		return;
	}

	let minLng = Infinity;
	let maxLng = -Infinity;
	let minLat = Infinity;
	let maxLat = -Infinity;

	mapPoints.forEach((coord) => {
		minLng = Math.min(minLng, coord[1]);
		maxLng = Math.max(maxLng, coord[1]);
		minLat = Math.min(minLat, coord[0]);
		maxLat = Math.max(maxLat, coord[0]);
	});

	const center = [(minLng + maxLng) / 2, (minLat + maxLat) / 2];

	map.current.setCenter(center);

	map.current.fitBounds(
		[
			[minLng, minLat],
			[maxLng, maxLat],
		],
		{
			padding,
			linear: true,
			maxDuration: 1000,
		},
	);
};

export default getCenterAndZoom;
