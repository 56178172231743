import maplibregl from "!maplibre-gl";
import { useEffect, useRef } from "react";
import createMarker from "../helpers/createMarker";
import calculateBearing from "../../../services/calculateBearing";

const useDrawMarker = (props) => {
  const {
    map,
    carMarkerRef,
    polylinePositions,
    bearingAccuracyMeters,
    coordsDriver,
    bearingCallBack,
    setNavigationCoords,
    drawMarkerRef,
    disabledCarMarker,
  } = props;

  const prevDriverCoords = useRef(null);
  const prevAnimationCoords = useRef(null);
  const bearingPrevCoords = useRef(null);

  const isDriverCoordsChange = JSON.stringify(prevDriverCoords.current) !== JSON.stringify(coordsDriver);

  useEffect(() => {
    if (!isDriverCoordsChange || disabledCarMarker) {
      return;
    }

    let rotation = null;

    if (!prevDriverCoords.current) {
      prevDriverCoords.current = coordsDriver;
    }

    // bearing rotation
    if (!bearingPrevCoords.current) {
      bearingPrevCoords.current = coordsDriver;
    }

    // Create custom marker element
    const markerElement = document.createElement("div");
    markerElement.className = "car-marker";

    // bearing
    let convertedPrevDriverCoords = new maplibregl.LngLat(
      bearingPrevCoords.current[0],
      bearingPrevCoords.current[1],
    );
    let convertedCoordsDriver = new maplibregl.LngLat(coordsDriver[0], coordsDriver[1]);

    const isBearingCoordsChange =
      convertedPrevDriverCoords.distanceTo(convertedCoordsDriver) > bearingAccuracyMeters;

    if (isBearingCoordsChange && bearingPrevCoords.current) {
      const bearing = calculateBearing(
        bearingPrevCoords.current[1],
        bearingPrevCoords.current[0],
        coordsDriver[1],
        coordsDriver[0],
      );

      if (bearing !== 0) {
        rotation = bearing;
        bearingPrevCoords.current = coordsDriver;
        bearingCallBack(rotation);
      }
    }
    prevDriverCoords.current = coordsDriver;

    if (!carMarkerRef.current) {
      createMarker({
        map,
        markerElement,
        position: coordsDriver,
        markerRef: carMarkerRef,
        rotation,
      });
    }

    // Function to animate the marker
    const animateMarker = (newLngLat, durationInSeconds = 1) => {
      const frameRate = 60; // Frames per second
      const totalFrames = durationInSeconds * frameRate;
      const animationStart = performance.now(); // Get the current time

      const startLngLat = prevAnimationCoords.current || coordsDriver;

      if (startLngLat) {
        const distanceLng = newLngLat[0] - startLngLat[0];
        const distanceLat = newLngLat[1] - startLngLat[1];

        const stepLng = distanceLng / totalFrames;
        const stepLat = distanceLat / totalFrames;

        const animate = (timestamp) => {
          const elapsed = timestamp - animationStart;
          const frame = Math.min(Math.floor((elapsed / 1000) * frameRate), totalFrames);

          if (frame < totalFrames) {
            const lng = startLngLat[0] + stepLng * frame;
            const lat = startLngLat[1] + stepLat * frame;
            carMarkerRef.current.setLngLat([lng, lat]);
            setNavigationCoords([lng, lat]);
            prevAnimationCoords.current = [lng, lat];
            requestAnimationFrame(animate);
          } else {
            carMarkerRef.current.setLngLat(coordsDriver);
          }
        };

        animate(animationStart);

        if (rotation) {
          carMarkerRef.current.setRotation(rotation);
        }

        prevAnimationCoords.current = newLngLat;
      }
    };

    //	 Example: Animate the marker to a new position when coordsDriver changes
    if (drawMarkerRef) {
      animateMarker(coordsDriver);
    } else {
      prevAnimationCoords.current = coordsDriver;
    }
  }, [
    coordsDriver,
    polylinePositions,
    bearingAccuracyMeters,
    isDriverCoordsChange,
    disabledCarMarker,
    carMarkerRef,
    drawMarkerRef,
    bearingCallBack,
    map,
    setNavigationCoords,
  ]);

  return null; // You may return a React component here if needed
};

export default useDrawMarker;
