import React from "react";
import styles from "./progressbar.module.scss";
import { CircularProgress } from "@mui/material";
import { FaCheck } from "react-icons/fa";
import { t } from "i18next";

export const CircularProgressBar = ({
	className,
	valuePercent,
	stepValue,
	finishStep,
	...props
}) => {
	return (
		<div className={className || styles.circularWrap}>
			<CircularProgress
				className={styles.circularBackground}
				size={78}
				style={{
					color:
						localStorage.getItem("theme") === "true" ? "#EEEEEE" : "#444348",
				}}
				thickness={4.5}
				value={100}
				variant="determinate"
				{...props}
			/>
			<CircularProgress
				className={styles.circular}
				size={78}
				thickness={4.5}
				value={valuePercent}
				variant="determinate"
				{...props}
			/>
			<div className={styles.infoStep}>
				{!finishStep ? (
					<>
						<p className={styles.step}>{t("step_v2")}</p>
						<p className={styles.numberStep}>
							{stepValue} {t("from_of")} 3
						</p>
					</>
				) : (
					<FaCheck className={styles.checkIcon} />
				)}
			</div>
		</div>
	);
};
