import { Button, ButtonVariant } from "src/shared/ui/Button/button";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "./styles.module.scss";

const SortingButton = (props) => {
  const { Icon, onClick, children, active, className, size = "mediun" } = props;

  const mods = {
    [styles.active]: active,
  };

  return (
    <Button
      className={classNames(styles.sortingButton, mods, [className, styles[size]])}
      onClick={onClick}
      variant={ButtonVariant.sorting}
    >
      <div className={classNames(styles.content, {}, [])}>
        {Icon && <Icon className={classNames(styles.icon, {}, [className])} />}
        {children}
      </div>
    </Button>
  );
};

export default SortingButton;
