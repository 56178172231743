import React from "react";

import { CircularProgress } from "@mui/material";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const ButtonVariant = {
  primary: "primary",
  secondary: "secondary",
  clear: "clear",
  sorting: "sorting",
  order: "order",
  outline: "outline",
  link: "link",
};

export const Button = (props) => {
  const {
    className,
    isLoading = false,
    loaderSize = 18,
    fullWidth,
    variant = ButtonVariant.primary,
    children,
    disabled,
    ...otherProps
  } = props;

  const buttonClasses = classNames(
    styles.Button,
    {
      [styles.fullWidth]: fullWidth,
      [styles.disabled]: disabled,
      [styles[variant]]: variant,
    },
    className,
  );

  return (
    <button className={buttonClasses} disabled={disabled} {...otherProps}>
      {isLoading ? <CircularProgress color="inherit" size={loaderSize} /> : children}
    </button>
  );
};
