import { t } from "i18next";

import { getValuteSymbol } from "src/services/getValuteSymbol";
import { paymentType } from "src/shared/constants";
import { ReactComponent as Wallet } from "src/images/wallet.svg";
import { ReactComponent as Cashback } from "src/images/cashback.svg";
import { ReactComponent as CreditCard } from "src/images/credit-card.svg";

import styles from "./styles.module.scss";
import Icon from "src/shared/ui/Icon/Icon";
import HighDemandColor from "src/components/HighDemandColor";

const OrderPriceBlock = (props) => {
  const { order } = props;
  return (
    <div className={styles.orderPriceBlock}>
      <div className={styles.orderPrice}>
        <Icon Svg={Wallet} className={styles.walletIcon} fill />
        <div className={styles.orderPriceInner}>
          <span>{t("cost")}</span>
          <span className={styles.fullOrderTotalQuote}>
          {order?.highDemandColor && <HighDemandColor highDemandColor={order?.highDemandColor} />}
            {order?.totalQuote}
            {getValuteSymbol(order.currency)}
          </span>
        </div>
      </div>
      <div className={styles.greyOrderLine} />
      <div className={styles.orderPriceBonuses}>
        <div className={styles.orderPriceBonusesItem}>
          <Icon Svg={CreditCard} fill />
          <div className={styles.orderPriceBonusesInner}>
            <span>{t(paymentType[order?.paymentType])}</span>
            <span>
              {order?.pricePaid}
              {getValuteSymbol(order.currency)}
            </span>
          </div>
        </div>
        /
        <div className={styles.orderPriceBonusesItem}>
          <Icon Svg={Cashback} fill />
          <div className={styles.orderPriceBonusesInner}>
            <span>{t("bonuses")}</span>
            <span>
              {order?.bonusPaid + order?.compensation}
              {getValuteSymbol(order.currency)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPriceBlock;
