import {
    UPD_NEWS_LIST,
    SEND_NEWS_ITEM,
    UPD_NOT_LIST,
    SEND_NOT_ITEM,
    GET_NEWS_LIST,
    GET_NOTIF_LIST,
    SET_ITEM_ID,
    ERROR_MSG,
    CLEAR_ERROR,
    } from './mainnews.actions.js'

const initialState = {
    news: [],
    newsitem: {},
    notifications: [],
    notification: {},
    itemId: '',
    errorStatus: false,
    errorMsg: "",
}

function mainnews(state = initialState, action) {
    switch (action.type) {
      case UPD_NEWS_LIST:
        return {...state, news: action.payload }
      case SEND_NEWS_ITEM:
        return {...state, newsitem: action.payload }
      case SEND_NOT_ITEM:
        return {...state, notification: action.payload }
      case UPD_NOT_LIST:
        return {...state, notifications: action.payload }
      case GET_NEWS_LIST:
        return {...state, news: action.payload }
      case GET_NOTIF_LIST:
        return {...state, notifications: action.payload }
      case SET_ITEM_ID:
        return {...state, itemId: action.payload }
      case ERROR_MSG:
        return {...state, errorMsg: action.payload, errorStatus: true }
      case CLEAR_ERROR:
        return {...state, errorMsg: "", errorStatus: false }
      default:
        return { ...state }
    }
  }
  export default mainnews