import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { t } from "i18next";

import { ReactComponent as MinusIcon } from "../../images/minus-circle.svg";
import { ReactComponent as PlusIcon } from "../../images/plus-circle.svg";
import { classNames } from "../../shared/lib/classNames/classNames";
import { sendRadiusToMap, setIsMapMoving } from "../Map/map.actions";
import { updDriverInfo } from "../RegistrationPage/registration.actions";
import "./orderradius.scss";

const minRadius = 2;
const stepRadius = 1;
const stepRadiusAfterTen = 5;
const maxRadius = 30;
const showUpdateStatus = false;

const OrderRadius = (props) => {
  const { driver, updDriverInfo, handleBeforeRadiusChange, sendRadiusToMap, setIsMapMoving } = props;

  const [radius, setRadius] = useState(driver.orderSearchDistance / 1000 || 4);

  useEffect(() => {
    if (driver?.callSign) {
      updateRadius();
      sendRadiusToMap(radius);
    }
  }, [radius]);

  useEffect(() => {
    if (driver?.orderSearchDistance) {
      setRadius(driver.orderSearchDistance / 1000);
    }
  }, [driver]);

  const updateRadius = async () => {
    const driverInfo = {
      phone: driver.phone,
      email: driver.email,
      firstName: driver.firstName,
      lastName: driver.lastName,
      middleName: driver.middleName,
      gender: driver.gender,
      photo: driver.photo,
      driverLicense: driver.driverLicense,
      cityId: driver.cityId,
      dateOfBirth: driver.dateOfBirth,
      orderSearchDistance: radius * 1000,
    };

    await updDriverInfo(driverInfo, showUpdateStatus);
  };

  const handleMinus = useCallback(() => {
    if (radius === minRadius) {
      return;
    }

    const radiusChangeStatus = handleBeforeRadiusChange();
    if (radiusChangeStatus) {
      return;
    }

    setIsMapMoving(false);

    if (radius <= 10) {
      setRadius((rad) => Math.max(minRadius, Math.floor(rad - stepRadius)));
    } else {
      setRadius((rad) => Math.max(minRadius, rad - stepRadiusAfterTen));
    }
  }, [radius]);

  const handlePlus = useCallback(() => {
    if (radius === maxRadius) {
      return;
    }

    const radiusChangeStatus = handleBeforeRadiusChange();
    if (radiusChangeStatus) {
      return;
    }

    setIsMapMoving(false);

    if (radius < 10) {
      setRadius((rad) => Math.min(maxRadius, Math.ceil(rad + stepRadius)));
    } else {
      setRadius((rad) => Math.min(maxRadius, rad + stepRadiusAfterTen));
    }
  }, [radius]);

  return (
    <div className="top-content_radius">
      <MinusIcon
        className={classNames("minusIcon", { btn_disabled: radius <= minRadius }, [])}
        onClick={handleMinus}
      />
      <div className="distance-wrap">
        <p className="distance-title">{t("en_route")}</p>
        <div className="value-content">
          <p className="value-radius">{`${radius}`}</p>
          <span className="value-unit">{t("km")}</span>
        </div>
      </div>
      <PlusIcon
        className={classNames("plusIcon", { btn_disabled: radius >= maxRadius }, [])}
        onClick={handlePlus}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    driver: state.registration.profinfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updDriverInfo: (driverInfo, showUpdateStatus) =>
      dispatch(updDriverInfo({ driverInfo, showUpdateStatus })),
    sendRadiusToMap: (radius) => dispatch(sendRadiusToMap(radius)),
    setIsMapMoving: (isMove) => dispatch(setIsMapMoving(isMove)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrderRadius));
