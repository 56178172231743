import React, { useEffect } from "react";

const useDebounce = ({ callback, condition = true, duration = 1000 }) => {
  useEffect(() => {
    if (condition) {
      const delayDebounceFn = setTimeout(() => {
        // Make API request with the current value
        callback();
      }, duration);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [callback, condition, duration]);
};

export default useDebounce;
