import { Suspense, lazy } from "react";

import { Route, Routes } from "react-router-dom";

import PrivateRoute from "src/components/PrivateRoute";
import ProtectedOrderPage from "src/components/ProtectedOrderPage";
import EtherOrdersPage from "src/pages/EtherOrdersPage";
import Loader from "src/pages/Loader";
import Order from "src/pages/Order";
import Orders from "src/pages/Orders";

const LazyMainNewsPage = lazy(() => import("src/pages/MainNews"));
const LazyHistoryPage = lazy(() => import("src/pages/History"));
const LazyAddCar = lazy(() => import("src/pages/AddCar"));
const LazyApkPolicy = lazy(() => import("src/pages/ApkPolicy"));
const LazyApkRules = lazy(() => import("src/pages/ApkRules"));
const LazyAuthPage = lazy(() => import("src/pages/AuthPage"));
const LazyCarDocuments = lazy(() => import("src/pages/CarDocuments"));
const LazyCars = lazy(() => import("src/pages/Cars"));
const LazyCheckCode = lazy(() => import("src/pages/CheckCode"));
const LazyChooseLanguages = lazy(() => import("src/pages/ChooseLanguages"));
const LazyContactUs = lazy(() => import("src/pages/ContactUs"));
const LazyDashboardPage = lazy(() => import("src/pages/DashboardPage"));
const LazyDeleteAccount = lazy(() => import("src/pages/DeleteAccount"));
const LazyEditCar = lazy(() => import("src/pages/EditCar"));
const LazyEditPassword = lazy(() => import("src/pages/EditPassword"));
const LazyEditProfile = lazy(() => import("src/pages/EditProfile"));
const LazyFinancialOperationPage = lazy(() => import("src/pages/FinancialOperationPage"));
const LazyFine = lazy(() => import("src/pages/Fine"));
const LazyFinishRegistrationPage = lazy(() => import("src/pages/FinishRegistrationPage"));
const LazyForgot = lazy(() => import("src/pages/Forgot"));
const LazyGTransactions = lazy(() => import("src/pages/GTransactions"));
const LazyGuideCarPhoto = lazy(() => import("src/pages/GuideCarPhoto"));
const LazyHistoryOrder = lazy(() => import("src/pages/HistoryOrder"));
const LazyLoginPage = lazy(() => import("src/pages/LoginPage"));
const LazyMoneyManagementPage = lazy(() => import("src/pages/MoneyManagementPage"));
const LazyMyReting = lazy(() => import("src/pages/MyReting"));
const LazyNewsArticle = lazy(() => import("src/pages/NewsArticle"));
const LazyNotifications = lazy(() => import("src/pages/Notifications"));
const LazyRegistrationPage = lazy(() => import("src/pages/RegistrationPage"));
const LazySTransactions = lazy(() => import("src/pages/STransactions"));
const LazySmsVerificationPage = lazy(() => import("src/pages/SmsVerificationPage"));
const LazyUploadPhotoDriver = lazy(() => import("src/pages/UploadPhotoDriver"));
const LazyInvoices = lazy(() => import("src/pages/Invoices"));
const LazyUserSupportInfo = lazy(() => import("src/pages/UserSupportInfo"));

const LazyRoutes = [
  <Route element={<LazyAddCar />} key="addcar" path="/addcar" />,
  <Route element={<LazyApkPolicy />} key="policy" path="/policy" />,
  <Route element={<LazyApkRules />} key="rules" path="/rules" />,
  <Route element={<LazyAuthPage />} key="authpage" path="/" />,
  <Route element={<LazyLoginPage />} key="login" path="/login" />,
  <Route element={<LazySmsVerificationPage />} key="sms-verification" path="/login/sms-verefication" />,
  <Route element={<LazyRegistrationPage />} key="registration" path="/registration" />,
  <Route element={<LazyCheckCode />} key="checkcode" path="/checkcode" />,
  <Route element={<LazyUploadPhotoDriver />} key="uploadphoto" path="/registration/uploadphoto" />,
  <Route element={<LazyFinishRegistrationPage />} key="finishreg" path="/finishreg" />,
  <Route element={<LazyCarDocuments />} key="cardocuments" path="/cardocuments" />,
  <Route element={<LazyGuideCarPhoto />} key="guide" path="/guide" />,
  <Route element={<LazyForgot />} key="forgot" path="/forgot" />,
  <Route element={<PrivateRoute Component={LazyMainNewsPage} />} key="mainnews" path="/mainnews" />,
  <Route element={<PrivateRoute Component={LazyHistoryPage} />} key="history" path="/history" />,
  <Route element={<PrivateRoute Component={LazyEditPassword} />} key="editpassword" path="/editpassword" />,
  <Route
    element={<PrivateRoute Component={LazyChooseLanguages} />}
    key="choose-language"
    path="/choose-language"
  />,
  <Route element={<PrivateRoute Component={LazyEditProfile} />} key="editprofile" path="/editprofile" />,
  <Route element={<PrivateRoute Component={LazyEditCar} />} key="editcar" path="/editcar" />,
  <Route element={<PrivateRoute Component={LazyCars} />} key="cars" path="/cars" />,
  <Route element={<PrivateRoute Component={LazyMyReting} />} key="myreting" path="/myreting" />,
  <Route element={<PrivateRoute Component={LazyNewsArticle} />} key="newsarticle" path="/newsarticle" />,
  <Route
    element={<PrivateRoute Component={LazyNotifications} />}
    key="notifications"
    path="/notifications"
  />,
  <Route element={<PrivateRoute Component={LazyContactUs} />} key="contactus" path="/contactus" />,
  <Route element={<PrivateRoute Component={LazyHistoryOrder} />} key="historyorder" path="/historyorder" />,
  <Route element={<PrivateRoute Component={LazyMoneyManagementPage} />} key="money" path="/money" />,
  <Route
    element={<PrivateRoute Component={LazyFinancialOperationPage} />}
    key="financial-operations"
    path="/financial-operations"
  />,
  <Route
    element={<PrivateRoute Component={LazyGTransactions} />}
    key="gtransactions"
    path="/gtransactions"
  />,
  <Route
    element={<PrivateRoute Component={LazySTransactions} />}
    key="stransactions"
    path="/stransactions"
  />,
  <Route element={<PrivateRoute Component={LazyFine} />} key="fines" path="/fines" />,
  <Route element={<PrivateRoute Component={LazyDashboardPage} />} key="dashboard" path="/dashboard" />,
  <Route
    element={<PrivateRoute Component={LazyDeleteAccount} />}
    key="delete-account"
    path="/delete-account"
  />,
  <Route element={<PrivateRoute Component={LazyInvoices} />} key="invoices" path="/invoices" />,
  <Route
    element={<PrivateRoute Component={LazyUserSupportInfo} />}
    key="user-support-info"
    path="/user-support-info"
  />,
];

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PrivateRoute Component={Orders} />} path="/orders" />
        <Route element={<ProtectedOrderPage Component={Order} />} path="/order" />
        <Route element={<PrivateRoute Component={EtherOrdersPage} />} path="/ether-orders" />
        {LazyRoutes}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
