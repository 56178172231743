import { memo } from "react";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "./styles.module.scss";

const Icon = (props) => {
  const { Svg, className, stroke, fill, invertedStroke, invertedFill, ...otherProps } = props;

  // Define the class names based on props
  const iconClassName = classNames(
    className,
    {
      [styles.iconStroke]: stroke,
      [styles.iconFill]: fill,
      [styles.invertedStroke]: invertedStroke,
      [styles.invertedFill]: invertedFill,
    }
  );


  return <Svg className={iconClassName} {...otherProps} />;
};

export default memo(Icon);
