import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

const TimeProgressBar = ({ totalTimeInSeconds, currentSeconds }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const updateProgress = () => {
			const newProgress = (currentSeconds / totalTimeInSeconds) * 100;
			setProgress(Math.min(newProgress, 100));

			if (newProgress <= 100) {
				requestAnimationFrame(updateProgress);
			}
		};

		updateProgress();
	}, [currentSeconds, totalTimeInSeconds]);

	return (
		<div className={styles.container}>
			<div
				className={styles.progressBar}
				style={{
					width: `${progress}%`,
				}}
			/>
		</div>
	);
};

export default TimeProgressBar;
