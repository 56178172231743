import { createAsyncThunk } from "@reduxjs/toolkit";

import { calculateLastDigits } from "src/components/MoneyManagement/helpers/calculateLastDigits";
import { getWithdrawT } from "src/pages/GTransactions/gtransactions.actions";
import { getDriverInfo } from "src/pages/RegistrationPage/registration.actions";

import { axiosInstance } from "../../../axios.config";
import { apinew } from "../../../config";

export const SEND_MONEY = "SEND_MONEY";
export const GET_MONEY = "GET_MONEY";
export const CONVERT_BONUSES = "CONVERT_BONUSES";
export const CLEAR_M = "CLEAR_M";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_NEW_CARD = "SET_NEW_CARD";
export const REJECT_TRANSACTION = "REJECT_TRANSACTION";

export const sendMoney = createAsyncThunk(
  "payment/sendMoney",
  async (info, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.post(
        `${apinew}/payment/replenishBalance`,
        {
          cardId: info.cardId,
          amount: info.amount,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      if (response?.status === 200 || response?.status === 204) {
        dispatch({ type: SEND_MONEY });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMoney = createAsyncThunk("payment/getMoney", async (info, { dispatch, rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.post(
      `${apinew}/payment/withdraw`,
      {
        driverId: info.driverId,
        cardId: info.cardId,
        amount: info.amount,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    if (response?.status === 200 || response?.status === 204) {
      dispatch({ type: GET_MONEY });
      await dispatch(getWithdrawT());
    }
  } catch (error) {
    //!TODO: CTD-204
    if (error.response?.data?.Data === "required_amount_is_too_big") {
      dispatch({
        type: REJECT_TRANSACTION,
      });
      return;
    }

    return rejectWithValue(error);
  }
});

export const ClearM = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_M,
    });
  };
};

export const BONUS_ERROR = "BONUS_ERROR";
export const convertBonuses = createAsyncThunk(
  "payment/convertBonuses",
  async (info, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.post(
        `${apinew}/payment/transferBonuses`,
        {
          driverId: info.driverId,
          amount: info.amount,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      if (response?.status === 200 || response?.status === 204) {
        dispatch({ type: CONVERT_BONUSES });
        await dispatch(getDriverInfo());
      }
    } catch (error) {
      //!TODO: CTD-204
      if (error.response && error.response?.data.Data === "required_amount_is_too_big") {
        dispatch({
          type: BONUS_ERROR,
        });
        return;
      }

      return rejectWithValue(error);
    }
  },
);

export const setNewCard = (cardInfo) => {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_CARD,
      payload: calculateLastDigits(cardInfo),
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};
