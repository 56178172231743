export const filterablePaymentTypes = {
  Cash: ["Cash", "CashAndBonus"],
  Card: ["CreditCard", "CreditCardAndBonus"],
};

export const paymentOptions = {
  Cash: "Cash",
  CashAndBonus: "CashAndBonus",
  CreditCard: "CreditCard",
  CreditCardAndBonus: "CreditCardAndBonus",
};

export const paymentMethods = {
  STRIPE: "Stripe",
  WAYFORPAY: "WayForPay",
};
