import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Badge, Paper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { getNewsList } from "src/pages/MainNews/store/mainnews.actions";

import { disableHistoryRoutes } from "src/shared/const/router";
import { classNames } from "src/shared/lib/classNames/classNames";

import { ReactComponent as MenuIcon } from "src/images/grid.svg";
import { ReactComponent as HomeIcon } from "src/images/home.svg";
import { ReactComponent as LiveOrdersIcon } from "src/images/live-menu.svg";
import { ReactComponent as MegaphoneIcon } from "src/images/megaphone.svg";
import { ReactComponent as TravelIcon } from "src/images/ridea.svg";

import SoundOnNewEther from "./components/SoundOnNewEther";
import { etherSounds } from "./constants";
import useCheckDistanceForSound from "./hooks/useCheckDistanceForSound";
import styles from "./styles.module.scss";
import { getFilteredEtherOrders } from "../EtherOrdersPage/store/selectors/getFilteredEtherOrders";

const BottomMenu = (props) => {
  const {
    getNewsList,
    storeNews,
    isDisabled,
    loginStatus,
    totalCount,
    atwork,
    currentOrderStatus,
    filteredEtherOrders,
  } = props;

  const [value, setValue] = useState(useLocation().pathname.slice(1) || "orders");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // ether sound
  const [playEtherOrder, setPlayEtherOrder] = useState(false);
  const [etherSoundSrc, setEtherSoundSrc] = useState(null);

  useEffect(() => {
    getNewsList();
  }, [getNewsList]);

  useEffect(() => {
    setValue(location.pathname.slice(1));
  }, [location]);

  const handleChange = useCallback(
    (_, newValue) => {
      navigate(`/${newValue}`);
    },
    [navigate],
  );

  const countUnreadNews = useCallback(() => {
    return storeNews.filter((news) => !news.isRead).length;
  }, [storeNews]);

  const orderStage = localStorage.getItem("orderStage");
  const showEtherMenuItem = atwork && !orderStage && !currentOrderStatus;

  // ether sound functions
  const onNewFarEther = () => {
    setPlayEtherOrder(true);
    setEtherSoundSrc(etherSounds.farSound);
  };

  const onNewEtherCloser = () => {
    setPlayEtherOrder(true);
    setEtherSoundSrc(etherSounds.closerSound);
  };
  // call sound on new ether order
  useCheckDistanceForSound({ filteredEtherOrders, onNewFarEther, onNewEtherCloser });

  if (!loginStatus || !disableHistoryRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <div className={classNames(styles.bottomNavigation, { [styles.isDisabledMenu]: isDisabled }, [])}>
      <SoundOnNewEther
        playEtherOrder={playEtherOrder}
        playSrc={etherSoundSrc}
        setPlayEtherOrder={setPlayEtherOrder}
      />

      <Paper
        elevation={2}
        sx={{ position: "fixed", width: "100%", bottom: 0, left: 0, right: 0, height: "80px" }}
      >
        <BottomNavigation onChange={handleChange} showLabels sx={{ height: "100%" }} value={value}>
          <BottomNavigationAction icon={<HomeIcon />} label={t("main")} value="orders" />
          {showEtherMenuItem && (
            <BottomNavigationAction
              icon={
                <Badge badgeContent={totalCount} color="error" overlap="rectangular">
                  <LiveOrdersIcon className={styles.etherIcon} />
                </Badge>
              }
              label={t("live")}
              sx={{ mb: "2px" }}
              value="ether-orders"
            />
          )}
          <BottomNavigationAction
            icon={
              <Badge badgeContent={countUnreadNews()} color="error" overlap="rectangular">
                <MegaphoneIcon />
              </Badge>
            }
            label={t("news")}
            value="mainnews"
          />
          <BottomNavigationAction
            icon={<TravelIcon className={styles.travelsIcon} />}
            label={t("trevels")}
            value="history"
          />
          <BottomNavigationAction icon={<MenuIcon />} label={t("options")} value="dashboard" />
        </BottomNavigation>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginStatus: state.login.loginStatus,
  storeNews: state.mainnews.news,
  totalCount: state.etherReducer.totalCount,
  atwork: state.orders.workStatus,
  currentOrderStatus: state.order.currentOrderStatus,
  filteredEtherOrders: getFilteredEtherOrders(state),
});

const mapDispatchToProps = {
  getNewsList,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(BottomMenu));
