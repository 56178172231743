import { memo } from "react";
import { t } from "i18next";

import ModalOrderInfoMenu from "src/pages/ModalOrder/components/ModalOrderInfoMenu";
import { FullOrderInfoWindow } from "../FullOrderInfoWindow";
import { secondsToMin } from "src/services/timeConvertor";
import FinalOrderMenu from "./components/FinalOrderMenu";

import { ReactComponent as DownLine } from "src/images/DownLine.svg";
import { ReactComponent as Navigation } from "src/images/Navigation.svg";
import { ReactComponent as MapPinLine } from "src/images/MapPinLine.svg";
import { ReactComponent as PauseCircle } from "src/images/PauseCircle.svg";
import { ReactComponent as CheckCircle } from "src/images/Check-new.svg";
import { ReactComponent as MapGreenPin } from "src/images/map-pin-green.svg";
import { ReactComponent as Clock } from "src/images/clock-v2.svg";
import { ReactComponent as Map } from "src/images/Map.svg";
import { ReactComponent as ArrowRight } from "src/images/arrow-right.svg";

import modalOrderStyles from "src/pages/ModalOrder/styles.module.scss";
import styles from "./styles.module.scss";
import { getOrderTime } from "src/services/getOrderTime";
import { useTimeToPoint } from "../../hooks/useTimeToPoint";
import useCheckUrlParam from "src/hooks/urlParamsHooks/useCheckUrlParam";
import useSetUrlParam from "src/hooks/urlParamsHooks/useSetUrlParam";
import useClearUrlParams from "src/hooks/urlParamsHooks/useClearUrlParams";
import { urlElementsParams } from "src/shared/const/urlParams";
import { parseJsonOrString } from "src/shared/helpers/parseJsonOrString";
import PointList from "./components/PointList";

const OrderInfoMenu = (props) => {
  const {
    showDetails,
    onDownLineClick,
    currentAdress,
    order,
    waitingLS,
    timeDurations,
    orderIconStage,
    buttonForOrderMenu,
    coordsDriver,
    stage,
    paidWait,
  } = props;

  const comment = parseJsonOrString(order.comment);
  const entranceNumber = comment?.entrance || null;

  // route list
  const destinationPoints = order.destinationPoints;

  const showOrderWindow = useCheckUrlParam({ paramValue: urlElementsParams.orderPopup });
  // const finishWork = waitingLS.getStartTimeWork();

  // popup functions
  const setUrlParam = useSetUrlParam({ paramValue: urlElementsParams.orderPopup });
  const onOpenOrderWindow = () => {
    setUrlParam();
  };

  //return from full order window
  const clearUrlParams = useClearUrlParams();
  const onReturnFromOrderWindow = () => {
    clearUrlParams();
  };

  let times = getOrderTime(timeDurations);

  /* method to choose icon to display by order state */
  const renderOrderStateIcon = () => {
    let icon;
    switch (orderIconStage) {
      //start of trip
      case 0:
        icon = <Navigation className="icon-svg order-icon" />;
        break;
      //in place (first point)
      case 1:
        icon = <MapPinLine className="icon-svg order-icon" />;
        break;
      //in pause
      case 2:
        icon = <PauseCircle className="icon-white-pause order-icon-closed" />;
        break;
      //trip ended (complete)
      case 3:
        icon = <CheckCircle className="icon-white order-icon-closed" />;
        break;
      default:
        icon = <Navigation className="icon-svg order-icon-closed" />;
    }
    return icon;
  };

  /* method to choose css for order's header by order state */
  const colorOrderState = () => {
    let color;
    switch (orderIconStage) {
      //start of trip
      case 0:
        color = "order-header";
        break;
      //in place (first point)
      case 1:
        color = "order-header";
        break;
      //in pause
      case 2:
        color = "order-header-pause";
        break;
      //trip ended (complete)
      case 3:
        color = "order-header-complete";
        break;
      default:
        color = "order-header";
    }
    return color;
  };
  /* Text for order header */
  const headerText = () => {
    switch (orderIconStage) {
      case 3:
        return t("done");
      default:
        return currentAdress?.mainText || currentAdress?.name || "";
    }
  };

  const showGiveCarTime = stage === 1;

  const time = useTimeToPoint({
    startCoordsPos: [coordsDriver.longitude, coordsDriver.latitude],
    endCoordsPos: [destinationPoints[0].lon, destinationPoints[0].lat],
    disabled: !showGiveCarTime,
  });

  const finalTime = typeof time === "number" && (time < 60 ? secondsToMin(60) : secondsToMin(time));

  const orderInfoItems =
    stage === 16
      ? [{ customElement: <FinalOrderMenu order={order} /> }]
      : [
          showGiveCarTime && {
            icon: <Clock />,
            iconText: t("give_car_v2"),
            value: finalTime ? `${finalTime}${t("minutes")}` : time,
          },
          {
            customElement: destinationPoints ? (
              <PointList destinationPoints={destinationPoints} entranceNumber={entranceNumber} />
            ) : null,
          },
          {
            icon: <Map />,
            iconText: t("trip_info"),
            value: <ArrowRight className={styles.arrowRight} />,
            onClick: onOpenOrderWindow,
          },
        ];

  return (
    <div>
      <div style={{ position: "relative" }}>
        <DownLine className="FaAngleDoubleDown" onClick={onDownLineClick} />
      </div>
      <div className={colorOrderState()}>
        <span className="text">
          <div style={{ display: "inline-block" }}>{renderOrderStateIcon()}</div>
          {headerText()}
        </span>
      </div>
      {showDetails && (
        <div className="checkpoint">
          {/* order timer */}
          {/* {((stage === 8 && !paidWait) || stage === 4 || paidWait) && (
						<div className="order-time-count">
							<MainTimer finishWork={finishWork} times={times} />
							<div className="order-time-count-single">
								<p className="order-time-text">{t("order_add_cost")}</p>
								<p className="order-time-text-count">
									+{order.pendingsQuote}
									<span>{getValuteSymbol(order.currency)}</span>
								</p>
							</div>
						</div>
					)} */}
          <ModalOrderInfoMenu orderInfoItems={orderInfoItems} />
        </div>
      )}

      <FullOrderInfoWindow
        buttonForOrderMenu={buttonForOrderMenu(clearUrlParams)}
        isOpen={showOrderWindow}
        onReturn={onReturnFromOrderWindow}
        order={order}
        showGiveCarTime={showGiveCarTime}
        timeToPoint={finalTime}
      />
    </div>
  );
};

export default memo(OrderInfoMenu);
