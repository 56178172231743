import  { useEffect } from "react";

import { farSoundDistance } from "../constants";

function isValueInArray(array, value) {
  return array.some((item) => item === value);
}
const useCheckDistanceForSound = (props) => {
  const { filteredEtherOrders, onNewFarEther, onNewEtherCloser } = props;

  useEffect(() => {
    if (filteredEtherOrders) {
      const sortedEtherPointsByDistance = filteredEtherOrders.sort(
        (a, b) => a.distanceToOrder - b.distanceToOrder,
      );
      const filteredEtherOrdersIds = sortedEtherPointsByDistance.map((item) => item.id);

      const prevEtherOrdersSoundIds = localStorage.getItem("prevEtherOrdersSoundIds")
        ? JSON.parse(localStorage.getItem("prevEtherOrdersSoundIds"))
        : [];

      const isSameEtherOrders = filteredEtherOrdersIds.every((etherItem) =>
        isValueInArray(prevEtherOrdersSoundIds, etherItem),
      );

      localStorage.setItem("prevEtherOrdersSoundIds", JSON.stringify(filteredEtherOrdersIds));
      if (isSameEtherOrders) {
        return;
      }

      const firstOrderDistance = sortedEtherPointsByDistance[0].distanceToOrder;

      if (firstOrderDistance <= farSoundDistance) {
        return onNewEtherCloser();
      }

      onNewFarEther();
    }
  }, [filteredEtherOrders, onNewFarEther, onNewEtherCloser]);
};

export default useCheckDistanceForSound;
