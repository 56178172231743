export const calculateCostPerKm = (distance, price) => {
	if (!distance || distance <= 1000) {
		return price
	}

	const costPerKm = price / (distance / 1000); // Делим общую цену на дистанцию в километрах
	const roundedCostPerKm = Number(costPerKm.toFixed(2));

	return roundedCostPerKm;
};
