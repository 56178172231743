import { memo, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { clearIncomeOrder } from "src/services/clearIncomeOrder";
import { callStopOrderRingtone } from "src/shared/funcForMobile";
import { classNames } from "src/shared/lib/classNames/classNames";
import Icon from "src/shared/ui/Icon/Icon";

import ModalOrderButtons from "./components/ModalOrderButtons";
import ModalOrderHeader from "./components/ModalOrderHeader";
import ModalOrderInfoMenu from "./components/ModalOrderInfoMenu";
import { useOrderDistance } from "./hooks/useOrderDistance";
import useOrderInfoItems from "./hooks/useOrderInfoItems";
import { getClasses } from "../AddCar/addcar.actions";
import ModalNew from "../Modals/ModalNew";
import {
  acceptIncomingOrder,
  clearOrder,
  closeMissedModalOrder,
  declineIncomingOrder,
} from "../Order/order.actions";

import { ReactComponent as WarningRed } from "src/images/warning-red.svg";

import styles from "./styles.module.scss";

const ModalOrder = (props) => {
  const {
    getClasses,
    missedOrder,
    order,
    acceptIncomingOrder,
    onClickModalOrder,
    declineIncomingOrder,
    closeMissedModalOrder,
    clearOrder,
    classes,
    driverCancelOrder,
    orderDuration,
  } = props;

  const [openFullModal, setOpenFullModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const { t } = useTranslation();

  const acceptOrder = async () => {
    setDisableBtn(true);

    const coordsDriver = JSON.parse(localStorage.getItem("coords"));

    const info = {
      orderId: order.orderId,
      lat: coordsDriver.latitude,
      lon: coordsDriver.longitude,
    };

    clearIncomeOrder();
    await acceptIncomingOrder(info);
    callStopOrderRingtone();
    onClickModalOrder();

    setDisableBtn(false);
  };

  const declineOrder = async () => {
    const orderId = order.orderId;

    clearIncomeOrder();

    if (orderId) {
      await declineIncomingOrder(orderId, 2);
      setShowCancelOrderModal(false);
      callStopOrderRingtone();
    }
  };

  const handleMissedModalOrderClose = () => {
    closeMissedModalOrder();
    onClickModalOrder();
    clearOrder();
    clearIncomeOrder();
  };

  const orderDistance = useOrderDistance(order.distance);
  const orderInfoItems = useOrderInfoItems({ order, orderDistance, styles });

  const currentClassAuto = classes.find((classAuto) => classAuto?.id === order?.carClassId);

  useEffect(() => {
    (async () => {
      await getClasses();
    })();
  }, [getClasses]);

  useEffect(() => {
    if (missedOrder) {
      localStorage.removeItem("timeForOrder");
    }
  }, [missedOrder]);

  return (
    <div className={classNames(styles.modalOrderWrapper, { [styles.modalOrderOpen]: openFullModal }, [])}>
      {showCancelOrderModal && !missedOrder && (
        <ModalNew
          buttonCancelText={t("no")}
          buttonConfirmText={t("yes")}
          image={<Icon Svg={WarningRed} />}
          onCancel={() => setShowCancelOrderModal(false)}
          onConfirm={declineOrder}
          title={t("do_you_want_cancel_order")}
        />
      )}
      <div className={styles.modalOrderHeader}>
        <ModalOrderHeader
          currentClassAuto={currentClassAuto}
          declineIncomingOrder={declineIncomingOrder}
          driverCancelOrder={driverCancelOrder}
          missedOrder={missedOrder}
          onHeaderClick={() => setOpenFullModal(!openFullModal)}
          order={order}
          orderDuration={orderDuration}
          setOpenFullModal={setOpenFullModal}
        />

        <div className={styles.modalOrderIncomeOrder}>
          <ModalOrderInfoMenu orderInfoItems={orderInfoItems} />
        </div>

        <ModalOrderButtons
          acceptOrder={acceptOrder}
          disableBtn={disableBtn}
          driverCancelOrder={driverCancelOrder}
          handleMissedModalOrderClose={handleMissedModalOrderClose}
          missedOrder={missedOrder}
          preOrderTime={order.preOrderTime}
          setShowCancelOrderModal={setShowCancelOrderModal}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    order: state.order.order,
    appCoords: state.app.coords,
    missedOrder: state.order.missedOrder,
    driverCancelOrder: state.order.driverCancelOrder,
    classes: state.addcar.classes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptIncomingOrder: (info) => dispatch(acceptIncomingOrder(info)),
    declineIncomingOrder: (orderId, reason, declineType) =>
      dispatch(declineIncomingOrder({ orderId, reason, declineType })),
    closeMissedModalOrder: () => dispatch(closeMissedModalOrder()),
    getClasses: () => dispatch(getClasses()),
    clearOrder: () => dispatch(clearOrder()),
  };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalOrder));
