import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../axios.config";
import { orderApi } from "../../config";

export const STARS_RATING = "STARS_RATING";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const fillStars = (rating) => {
  return (dispatch) => {
    dispatch({
      type: STARS_RATING,
      payload: rating,
    });
  };
};

export const setClientRating = createAsyncThunk(
  "driver/setClientRating",
  async (clientRatingData, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `${orderApi}/driver/rate`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          orderId: clientRatingData.orderId,
          rating: clientRatingData.rating,
          comment: clientRatingData.comment,
        },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};
