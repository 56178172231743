import moment from "moment/moment";
import { preOrderTimeDifferenceInMinutes } from "src/shared/constants";

export const getRemainingTimeOrWithinLimit = (preOrderTime) => {
  const currentTime = moment.utc();
  const preOrderDateTime = moment.utc(preOrderTime);

  const timeDifferenceInMinutes = preOrderDateTime.diff(currentTime, "minutes");

  if (timeDifferenceInMinutes < preOrderTimeDifferenceInMinutes) {
    return true;
  } else {
    const formattedTime = preOrderDateTime
      .subtract(preOrderTimeDifferenceInMinutes, "minutes")
      .local()
      .format("HH:mm");

    return formattedTime;
  }
};
