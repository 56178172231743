import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../../axios.config";
import { apinew } from "../../../config";

export const UPD_NEWS_LIST = "UPD_NEWS_LIST";
export const SEND_NEWS_ITEM = "SEND_NEWS_ITEM";
export const UPD_NOT_LIST = "UPD_NOT_LIST";
export const SEND_NOT_ITEM = "SEND_NOT_ITEM";
export const GET_NEWS_LIST = "GET_NEWS_LIST";
export const GET_NOTIF_LIST = "GET_NOTIF_LIST";
export const SET_ITEM_ID = "SET_ITEM_ID";
//export const UPD_NEWS_ITEM = 'UPD_NEWS_ITEM'
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const updNewsList = (newsList) => {
  return (dispatch) => {
    dispatch({
      type: UPD_NEWS_LIST,
      payload: newsList,
    });
  };
};

export const setItemId = (item) => {
  return (dispatch) => {
    dispatch({
      type: SET_ITEM_ID,
      payload: item,
    });
  };
};

export const getNewsItem = createAsyncThunk(
  "news/getNewsItem",
  async (newsItem, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${apinew}/profile/news/${newsItem}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200 || response.status === 204) {
        dispatch({ type: SEND_NEWS_ITEM, payload: response.data.data });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updNewsItem = createAsyncThunk("news/updNewsItem", async (newsItem, { rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    await axiosInstance({
      method: "put",
      url: `${apinew}/profile/news/${newsItem}/set-read`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { id: newsItem },
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendNotItem = (notItem) => {
  return (dispatch) => {
    dispatch({
      type: SEND_NOT_ITEM,
      payload: notItem,
    });
  };
};

export const updNotList = (notList) => {
  return (dispatch) => {
    dispatch({
      type: UPD_NOT_LIST,
      payload: notList,
    });
  };
};

export const getNewsList = createAsyncThunk("news/getNewsList", async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(`${apinew}/profile/news`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    dispatch({ type: GET_NEWS_LIST, payload: response.data.data });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getNotifList = createAsyncThunk(
  "notifications/getNotifList",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${apinew}/profile/notifications`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch({ type: GET_NOTIF_LIST, payload: response.data.data });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};
