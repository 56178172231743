import React, { memo, useState } from "react";
import { t } from "i18next";
import CustomDialog from "src/components/CustomDialog";
import { ReactComponent as DownArrowIcon } from "src/images/down-arrow.svg";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Button/button";
import { Input } from "src/shared/ui/Input/inputs";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import Select from "src/shared/ui/Select";
import styles from "./styles.module.scss";
import generateYearsArray from "src/services/generateYearsArray";
import { allowCarYears } from "src/shared/constants";

const years = generateYearsArray(allowCarYears);

const AddNewCar = ({ brands, colors, models, setDefaultExpressCar, setStep }) => {
  const [carInfo, setCarInfo] = useState({
    brandId: null,
    modelId: null,
    colorId: null,
    year: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [numberCar, setNumberCar] = useState("");
  const [selectorType, setSelectorType] = useState(null);

  const modelList = models.filter((model) => model.brandId === carInfo.brandId);

  const setCurrentBrandId = (brandId) => setCarInfo((prev) => ({ ...prev, brandId }));
  const setCurrentModal = (modelId) => setCarInfo((prev) => ({ ...prev, modelId }));
  const setCurrentYear = (year) => setCarInfo((prev) => ({ ...prev, year }));
  const setCurrentColor = (colorId) => setCarInfo((prev) => ({ ...prev, colorId }));

  const onToggle = (type) => {
    setSelectorType(type);
    setIsOpen((prev) => !prev);
  };

  const handleChange = (e) => setNumberCar(e.target.value.toUpperCase());

  const isCarInfoValid = Object.values(carInfo).every((value) => value !== null && numberCar.length >= 4);

  const currentBrand = brands.find((brand) => brand.id === carInfo.brandId);
  const currentModel = modelList.find((model) => model.id === carInfo.modelId);
  const currentColorId = colors.find((color) => color.id === carInfo.colorId);

  const handleAddCar = async () => {
    const driverId = localStorage.getItem("driverId");

    const carData = {
      brandId: carInfo.brandId,
      modelId: carInfo.modelId,
      colorId: carInfo.colorId,
      govNumber: numberCar,
      manufactureYear: carInfo.year,
      driverId,
      expresscar: 1,
    };

    await setDefaultExpressCar(carData);
    setStep(2);
  };

  const getSelector = () => {
    switch (selectorType) {
      case "brand":
        return (
          <Select
            currentId={carInfo.brandId}
            listItem={brands}
            onSelectOptionId={setCurrentBrandId}
            title={t("car_brand")}
            toggleSelect={() => onToggle(null)}
            topCustomComponent={<MobileTopBarHeight />}
          />
        );
      case "model":
        return (
          <Select
            currentId={carInfo.modelId}
            listItem={modelList}
            onSelectOptionId={setCurrentModal}
            title={t("car_model_search")}
            toggleSelect={() => onToggle(null)}
            topCustomComponent={<MobileTopBarHeight />}
          />
        );
      case "year":
        return (
          <Select
            currentId={carInfo.year}
            listItem={years}
            onSelectOptionId={setCurrentYear}
            title={t("year_of_production")}
            toggleSelect={() => onToggle(null)}
            topCustomComponent={<MobileTopBarHeight />}
          />
        );
      case "color":
        return (
          <Select
            currentId={carInfo.colorId}
            listItem={colors}
            onSelectOptionId={setCurrentColor}
            title={t("car_color_choice")}
            toggleSelect={() => onToggle(null)}
            topCustomComponent={<MobileTopBarHeight />}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.AddNewCar}>
      <CustomDialog
        bodyOptions={{
          bodyDisableObserver: true,
          pageLayoutClassname: styles.withoutPadding,
        }}
        content={getSelector()}
        fullScreen
        headerOptions={{
          showHeaderBackIcon: false,
        }}
        open={isOpen}
      />

      <div className={styles.setCarInfo}>
        <p>{t("car_brand")}</p>
        <Button className={styles.btnSelect} onClick={() => onToggle("brand")}>
          <span className={classNames(styles.selectItem, { [styles.active]: carInfo.brandId }, [])}>
            {!carInfo.brandId ? t("car_brand") : currentBrand?.name}
          </span>
          <span>
            <DownArrowIcon className={styles.downIcon} />
          </span>
        </Button>
      </div>

      <div className={styles.setCarInfo}>
        <p>{t("car_model")}</p>
        <Button className={styles.btnSelect} onClick={() => onToggle("model")}>
          <span className={classNames(styles.selectItem, { [styles.active]: carInfo.modelId }, [])}>
            {!carInfo.modelId ? t("car_model") : currentModel?.name}
          </span>
          <span>
            <DownArrowIcon className={styles.downIcon} />
          </span>
        </Button>
      </div>

      <div className={styles.carType}>
        <div className={styles.setCarInfo}>
          <p>{t("year_of_production")}</p>
          <Button className={styles.btnSelect} onClick={() => onToggle("year")}>
            <span className={classNames(styles.selectItem, { [styles.active]: carInfo.year }, [])}>
              {!carInfo.year ? t("year_of_production") : carInfo.year}
            </span>
            <span>
              <DownArrowIcon className={styles.downIcon} />
            </span>
          </Button>
        </div>
        <div className={styles.setCarInfo}>
          <p>{t("car_color_v2")}</p>
          <Button className={styles.btnSelect} onClick={() => onToggle("color")}>
            <span className={classNames(styles.selectItem, { [styles.active]: carInfo.colorId }, [])}>
              {!carInfo.colorId ? t("car_color_v2") : currentColorId?.name}
            </span>
            <span>
              <DownArrowIcon className={styles.downIcon} />
            </span>
          </Button>
        </div>
      </div>

      <div className={styles.setCarInfo}>
        <p>{t("license_plate")}</p>
        <Input
          className={styles.numberAuto}
          handleClearValue={() => setNumberCar("")}
          name="number"
          onChange={handleChange}
          placeholder="AA0000AA"
          value={numberCar}
        />
      </div>

      <Button
        className={classNames(styles.confirmBtn, { [styles.disabled]: !isCarInfoValid }, [])}
        disabled={!isCarInfoValid}
        fullWidth
        onClick={handleAddCar}
      >
        {t("confirm")}
      </Button>
    </div>
  );
};

export default memo(AddNewCar);
