import { useEffect, useState } from "react";
import { getGpsStatus } from "src/shared/api/getGpsStatus";
import { timeToCheckGpsSignal } from "src/shared/constants";
import { onManualLocationChanged } from "src/shared/funcForMobile";

const useCheckGpsStatus = () => {
  const [gpsSignalBlocked, setGpsSignalBlocked] = useState(
    localStorage.getItem("gpsSignalBlocked") ? JSON.parse(localStorage.getItem("gpsSignalBlocked")) : false,
  );

  // check gps status
  useEffect(() => {
    const checkBlockGps = async () => {
      let gpsSignalBlockedLocalStore = localStorage.getItem("gpsSignalBlocked")
        ? JSON.parse(localStorage.getItem("gpsSignalBlocked"))
        : null;

      if (gpsSignalBlockedLocalStore === null) {
        localStorage.setItem("gpsSignalBlocked", false);
        gpsSignalBlockedLocalStore = false;
      }

      const response = await getGpsStatus();
      const status = response?.data?.blocked || false;

      if (status === gpsSignalBlockedLocalStore && status === gpsSignalBlocked) {
        return;
      }

      localStorage.setItem("gpsSignalBlocked", status);
      setGpsSignalBlocked(status);

      // disabled status for mobile app
      if (!status) {
        onManualLocationChanged(null);
        // deactivate manual mod for frontend
        localStorage.setItem("isActiveManualMod", false);
      }
    };

    // call on first load
    checkBlockGps();

    // call every timeToCheckGpsSignal
    const checkBlockGpsInterval = setInterval(checkBlockGps, timeToCheckGpsSignal);

    return () => {
      clearInterval(checkBlockGpsInterval);
    };
  }, [gpsSignalBlocked]);
  return gpsSignalBlocked;
};

export default useCheckGpsStatus;
