import React from "react";
import { ReactComponent as MapGreenPin } from "src/images/map-pin-green.svg";

import modalOrderStyles from "src/pages/ModalOrder/styles.module.scss";
import styles from "./styles.module.scss";
import { t } from "i18next";

const PointList = (props) => {
  const { destinationPoints, entranceNumber } = props;
  return (
    <div className={modalOrderStyles.destinationPoints}>
      {destinationPoints.map((item, index) => {
        const lastItem = index === destinationPoints.length - 1;
        const firstItem = index === 0;
        const showEntranceNumber = firstItem && entranceNumber;
        return (
          <div
            className={`${modalOrderStyles.destinationPoint} ${
              lastItem ? modalOrderStyles.destinationPointLast : ""
            }`}
            key={`${item.name}_${item.index}`}
          >
            {lastItem ? (
              <MapGreenPin className={modalOrderStyles.lastItemOrder} />
            ) : (
              <div
                className={`${modalOrderStyles.pointNumBlack} ${
                  firstItem ? modalOrderStyles.pointNumFirst : ""
                }
            ${lastItem ? modalOrderStyles.pointNumLast : ""}
            `}
              >
                <span className={modalOrderStyles.innerPointNumBlack}>{!firstItem && index + 1}</span>
              </div>
            )}

            <div className={styles.addressTextBlock}>
              <span className={styles.mainText}>{item.mainText || item.name}</span>
              {showEntranceNumber && (
                <span className={styles.mainText}>
                  , {t("entrance")} {entranceNumber}
                </span>
              )}
              {item.secondaryText && <p className={styles.secondaryText}>{item.secondaryText}</p>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PointList;
