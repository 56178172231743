import { memo, useCallback, useEffect, useMemo, useState } from "react";

import CustomMap from "src/components/CustomMap";

import Loader from "src/pages/Loader";


import { classNames } from "src/shared/lib/classNames/classNames";
import { Modal } from "src/shared/ui/Modal";

import styles from "./styles.module.scss";

const EtherOrderMap = (props) => {
  const { destinationPoints, polylinePositions } = props;
  const driverCoordinates = JSON.parse(localStorage.getItem("coords"));

  const [mapLoaded, setMapLoaded] = useState(false);
  const [zoomFullRoute, setZoomFullRoute] = useState(false);
  const [mapOpened, setMapOpened] = useState(false);

  const onClickMap = useCallback((e) => {
    if (e.target.classList.contains("maplibregl-canvas")) {
      setMapOpened(true);
    }
  }, []);


  const Map = (
    <CustomMap
      calculateZoomByPoints={true}
      className={classNames(styles.map, { [styles.showMap]: !mapLoaded }, ["orderMap"])}
      coordsDriver={[driverCoordinates.longitude, driverCoordinates.latitude]}
      disabledFunctions={{
        disableSeparateRoute: true,
        disableButtons: true,
        disableGlueCar: true,
        disabledSpeed: true,
        disabledNavigation: true,
        disableRadius: true,
        disableInteractive: !mapOpened,
        disableCircle: true,
      }}
      mapLoaded={mapLoaded}
      mapPoints={destinationPoints}
      polylinePositions={polylinePositions}
      setMapLoaded={setMapLoaded}
      setZoomFullRoute={setZoomFullRoute}
      showPopup={mapOpened}
      zoomFullRoute={zoomFullRoute}
    />
  );

  return (
    <div className={styles.EtherOrderMap} onClick={onClickMap}>
      {!mapLoaded && <Loader className={styles.loader} size={50} />}

      {driverCoordinates && Map}

      <Modal fullSize isOpen={mapOpened} noPadding onClose={() => setMapOpened(false)}>
        <div className={styles.mapModal} id="modal-map">
          {driverCoordinates && Map}
        </div>
      </Modal>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (prevProps.destinationPoints.length !== nextProps.destinationPoints.length) {
    return false;
  }

  return prevProps.destinationPoints.every((prevPoint, index) => {
    const nextPoint = nextProps.destinationPoints[index];
    return (
      prevPoint.name === nextPoint.name &&
      prevPoint.lat === nextPoint.lat &&
      prevPoint.lon === nextPoint.lon &&
      prevPoint.pointId === nextPoint.pointId &&
      prevPoint.mainText === nextPoint.mainText &&
      prevPoint.secondaryText === nextPoint.secondaryText
    );
  });
};
export default memo(EtherOrderMap, areEqual);
