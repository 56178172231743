import React, { Fragment, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { ReactComponent as ErrorIcon } from "src/images/error.svg";
import styles from "./styles.module.scss";
import { Button } from "src/shared/ui/Button/button";
import { classNames } from "src/shared/lib/classNames/classNames";
import Loader from "src/pages/Loader";
import TimeProgressBar from "src/shared/ui/TimeProgressBar";
import { Trans } from "react-i18next";
import useCountdownTimer from "src/hooks/useCountdownTimer";

const findItemById = (id, array) => array.find((item) => item.id === id);
const localStorageKey = "interactionAllowedTime";
const initialSeconds = 12;

const CurrentCar = (props) => {
  const {
    cars,
    classes,
    brands,
    colors,
    models,
    getBrands,
    getClasses,
    getColors,
    getModels,
    setStep,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const { seconds } = useCountdownTimer(initialSeconds, localStorageKey, false);

  const changeStep = (step) => {
    setStep(step);
  };

  const handleClickOk = () => {
    changeStep(2);
  };

  const renderCarInfoBlock = (car) => (
    <Fragment key={car.id}>
      <div className={styles.carInfoBlock}>
        <h2 className={styles.brand}>
          {`${findItemById(car.brandId, brands)?.name} ${
            findItemById(car.modelId, models)?.name
          }`}
        </h2>
        <h2 className={styles.govnumberCar}>{car.govNumber}</h2>
      </div>

      <div className={styles.carInfoBlock}>
        <p className={styles.brand}>
          {t(findItemById(car.colorId, colors)?.name)}
        </p>
        <p>{car.manufactureYear}</p>
      </div>

      <div className={styles.carInfoBlock}>
        <p className={styles.brand}>{t("car_class")}</p>
        <p>{t(findItemById(car.classId, classes)?.name)}</p>
      </div>
    </Fragment>
  );

  const findDefaultCar =
    cars.filter((car) => Boolean(car.isDefault)) || cars[0];

  useEffect(() => {
    setIsLoading(true);

    const fetchCarData = async () => {
      await Promise.all([getBrands(), getClasses(), getModels(), getColors()]);
      setIsLoading(false);
    };

    fetchCarData();
  }, [getBrands, getClasses, getColors, getModels]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.CurrentCar}>
      <div className={styles.wrapperCar}>
        {findDefaultCar?.map(renderCarInfoBlock)}
      </div>
      <div className={styles.warning}>
        <ErrorIcon className={styles.errorIcon} />
        <p>{t("answer_carefully_to_avoid_blocking_your_account")}</p>
      </div>
      <div className={styles.loadenToBtn}>
        {seconds > 0 && (
          <div className={styles.loader}>
            <div className={styles.value}>
              <h2>{t("you_can_answer_throw")}</h2>
              <Trans i18nKey="time_in_sec" values={{ time: seconds }} />
            </div>
            <TimeProgressBar
              currentSeconds={seconds}
              totalTimeInSeconds={initialSeconds}
            />
          </div>
        )}
        <div className={styles.buttons}>
          <Button
            className={classNames(
              styles.acceptBtn,
              { [styles.disabledBtn]: Boolean(seconds) },
              [],
            )}
            disabled={seconds > 0}
            fullWidth
            onClick={handleClickOk}
          >
            {t("all_right")}
          </Button>
          <Button
            className={classNames(
              styles.declineBtn,
              { [styles.disabledBtn]: Boolean(seconds) },
              [],
            )}
            disabled={seconds > 0}
            fullWidth
            onClick={() => changeStep(1)}
          >
            {t("no")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrentCar;
