import React, { forwardRef } from "react";
import { ReactComponent as WarningRed } from "src/images/warning-red.svg";
import { BlackButton } from "src/shared/ui/Buttons/BlackButton";

import styles from "./styles.module.scss";
import ModalNew from "src/pages/Modals/ModalNew";
import { t } from "i18next";
import { BorderedButton } from "src/shared/ui/Buttons/BorderedButton";

const ManualCoordsPopup = ({ onClick, onSecondButtonClick, onReturnToGps, showAlertPopup, isActiveManualMod }, ref) => {
  // if isActiveManualMod disabled
  const popupInner = (
    <div className={styles.manualCoordsPopup} ref={ref}>
      <WarningRed className={styles.warningImg} />
      <h2>{t("air_alert_title")}</h2>
      <p>{t("air_alert_des")}</p>
      <BlackButton className={styles.confirmBtn} onClick={onClick}>
        {t("set_geopos")}
      </BlackButton>
      <BorderedButton onClick={onSecondButtonClick}>{t("continue_with_gps")}</BorderedButton>
    </div>
  );

  // if isActiveManualMod active
  const popupInnerIsManual = (
    <div className={styles.manualCoordsPopup} ref={ref}>
      <BlackButton className={styles.confirmBtn} onClick={onClick}>
        {t("update_location")}
      </BlackButton>
      <BorderedButton onClick={onReturnToGps}>{t("return_to_gps")}</BorderedButton>
    </div>
  );

  return (
    <ModalNew
      customInnerComponent={isActiveManualMod ? popupInnerIsManual : popupInner}
      newModalContentClassName={styles.newModalContentClassName}
      newModalWrapClassName={`${styles.alertPopupWrap} ${showAlertPopup ? styles.alertPopupWrapActive : ""}`}
    />
  );
};

export default forwardRef(ManualCoordsPopup);
