import React from "react";
import { t } from "i18next";
import InputAdornment from "@mui/material/InputAdornment";

import { BlackButton } from "src/shared/ui/Buttons/BlackButton";
import DefaultInput from "src/shared/ui/Inputs/DefaultInput";

import styles from "./styles.module.scss";

import { ReactComponent as MapPinGreen } from "src/images/map-pin-green.svg";
import { ReactComponent as CloseIcon } from "src/images/close.svg";

const SearchComponent = ({
  customSearchComponentWrap = "",
  onSubmit = () => null,
  onClear,
  ...otherInputProps
}) => {
  return (
    <div className={`${styles.searchComponentWrap} ${customSearchComponentWrap}`}>
      <DefaultInput
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MapPinGreen className={styles.searchComponentPinImg} />
            </InputAdornment>
          ),
          endAdornment: <CloseIcon className={styles.closeIcon} onClick={onClear} />,
        }}
        customClassName={styles.searchComponentInput}
        type="text"
        variant="outlined"
        {...otherInputProps}
      />
      <BlackButton className={styles.confirmBtn} onClick={onSubmit}>
        {t("confirm")}
      </BlackButton>
    </div>
  );
};

export default SearchComponent;
