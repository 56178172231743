import React, { Component, Fragment, Suspense } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { showMenu } from "../Menu/menu.actions";

import "./order.scss";
import { t } from "i18next";

import { checkPushOrderAccepted } from "../Orders/orders.actions";
import { minArrivalDistanceMeters } from "../../config";

import { getRoutePolyline } from "./helpers/getRoutePolilyne";
import { checkArrivalDistance } from "src/services/checkArrivalDistance";
import OrderInfoMenu from "./components/OrderInfoMenu";
import { waitingLS } from "src/services/waitingLS";
import { allowArriveRadiusInMeters, orderIconStages } from "src/shared/constants";
import ReviewOrder from "./components/ReviewOrder";
import withNavigation from "src/hoc/NavigateForClass";
import Loader from "../Loader";
import CustomMap from "src/components/CustomMap";
import { updateCoords } from "src/shared/api/updateCoords";
import ModalConfirm from "../Modals/ModalConfirm";
import { clearIncomeOrder } from "src/services/clearIncomeOrder";
import SetManualCoords from "src/feature/SetManualCoords";
import { onContentLoaded } from "src/shared/funcForMobile";
import { getRemainingTimeOrWithinLimit } from "./helpers/getRemainingTimeOrWithinLimit";
import { calculateHaversineDistance } from "./helpers/calculateHaversineDistance";
import CancelOrderButton from "./components/CancelOrderButton";
import { CANCEL_ORDER_LAST_ACTIVE_TIME } from "src/shared/const/localstorage";
import { checkPauseCoords } from "./helpers/checkPauseCoords";
import { parseLocalStorageItem } from "src/services/localstorage/parseLocalStorageItem";
import { maxPauseDistance, orderStages } from "./constants";
import ChangeDriverButton from "./components/ChangeDriver/comnponents/ChangeDriverButton";
import OrderMainButtons from "./components/OrderMainButtons";
import TimerPaid from "./components/Timer";
import { paidWaiting, checkWaitingTime } from "./components/Timer/timer.actions";
import {
  rejectOrder,
  arriveOrder,
  clientOnBoard,
  completeOrder,
  paidOrder,
  sendOrderPoint,
  paidByCard,
  clearError,
  currentOrderInfo,
  clearOrder,
} from "./order.actions";
import { findSegment } from "../../components/CustomMap/helpers/findSegment";
import { CURRENT_ORDER_STATUS_CHECK_MAX_ATTEMPTS } from "../../config-in-git";
import { getValuteSymbol } from "../../services/getValuteSymbol";
import BottomMenu from "../BottomMenu";
import ModalAlert from "../ModalAlert";
import { showModalAlert } from "../ModalAlert/modalAlert.actions";
import { showModalConfirm } from "../Modals/ModalConfirm/modalConfirm.actions";
import { withRTKQuery } from "./hoc/withRTKQuery";

const commonCleanup = () => {
  localStorage.removeItem("currentAdress");
  localStorage.removeItem("passedPoints");
  localStorage.removeItem("passedRoute");
};

const getStartTime = () => {
  const seconds = localStorage.getItem("paidWait");
  if (seconds) {
    const timeInUNIX = localStorage.getItem("startTimer");
    const timeNowUNIX = Math.floor(Date.now() / 1000);
    const correctSeconds = timeNowUNIX - timeInUNIX;
    localStorage.setItem("paidWait", correctSeconds);
    return correctSeconds;
  }
  return null;
};

const maxDistanceToPolyline = 35;

class Order extends Component {
  state = {
    showDetails: true,
    stage: Number(localStorage.getItem("orderStage")),
    mainConfirmText: "",
    mainAlertText: "",
    timeLeft: "",
    distanceLeft: "",
    isGetForMap: false,
    bonusPaid: "",
    showPbCash: false,
    paidByCard: "",
    paidWait: getStartTime(),
    addressPointIndex: 0,
    isCorrectStatusId: false,
    orderIconStage: 0,
    isOpenMoreOrder: false,
    currentAdress: null,
    timeDurations: null,
    routeData: [],
    polylinePositions: null,
    newPolyPoints: null,
    mapPoints: null,
    zoomFullRoute: true,
    zoomOnClientOn: true,
    mapLoaded: false,
    coordsDriver: null,
    speed: null,
    passedPoints: [],
    polylineError: false,
  };

  manageCancelOrderTimer = () => {
    if (this.state.stage !== orderStages.CarArrived) {
      localStorage.removeItem(CANCEL_ORDER_LAST_ACTIVE_TIME);
    }
  };

  async componentDidMount() {
    const currentAdress = JSON.parse(localStorage.getItem("currentAdress"));
    const stage = Number(localStorage.getItem("orderStage"));
    if (currentAdress) {
      this.setState({ currentAdress: currentAdress });
    }

    let status = !this.props.showMAlert;

    if (status === true) {
      this.props.showModalAlert(false);
    }

    this.props.currentOrderInfo(this.props.navigate);

    this.setState({
      stage,
      orderIconStage: orderIconStages[stage],
    });

    localStorage.setItem("readyForOrder", false);

    this.distanceInterval = setInterval(async () => {
      this.setState({
        timeLeft: localStorage.getItem("timeLeft"),
        distanceLeft: localStorage.getItem("distanceLeft"),
      });
    }, 5000);

    // this.coordsInOrderRadiusInterval = setInterval(() => {
    //   const allowArrivedCoords = localStorage.getItem("allowArrivedCoords")
    //     ? JSON.parse(localStorage.getItem("allowArrivedCoords"))
    //     : [];
    //   if (allowArrivedCoords.length === 5) {
    //     return clearInterval(this.coordsInOrderRadiusInterval);
    //   }
    //   this.setArrivedDriverCoords();
    // }, 2000);

    this.coordsCheckInterval = setInterval(async () => {
      //request new polyline route if driver move on 35m from new newPolyPoints
      const coordsDriver = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : null;

      if (this.state.newPolyPoints !== null) {
        const passedPoints = localStorage.getItem("passedPoints")
          ? JSON.parse(localStorage.getItem("passedPoints"))
          : [];

        if (coordsDriver) {
          const editedCoordsDriver = [coordsDriver.latitude, coordsDriver.longitude];

          const distanceInMeters = findSegment(
            this.state.newPolyPoints,
            editedCoordsDriver,
          )?.distanceInMeters;

          // rebuild route if we passed point or drive left route on 35m

          const driverCoordsChanged =
            JSON.stringify(this.state.coordsDriver) !== JSON.stringify(coordsDriver);

          const passedPointsChanged =
            passedPoints.length !== this.state.mapPoints.length ||
            passedPoints.length !== this.state.passedPoints.length;

          if (distanceInMeters > maxDistanceToPolyline && passedPointsChanged && driverCoordsChanged) {
            await getRoutePolyline({
              state: this.state,
              orderDestinationPoints: this.props.order.destinationPoints,
              setState: (items) => this.setState({ ...items }),
              polylineErrorHandler: this.polylineErrorHandler,
            });
            this.setState({
              passedPoints,
            });
          }

          const speed = (Number(coordsDriver.speed) * 3.6).toFixed(0);

          //speed
          if (JSON.stringify(speed) !== JSON.stringify(this.state.speed)) {
            this.setState({ speed });
          }
        }
      }

      // coordsDriver
      if (JSON.stringify(coordsDriver) !== JSON.stringify(this.state.coordsDriver)) {
        this.setState({
          coordsDriver,
        });
      }
    }, 100);

    if (this.state.coordsDriver) {
      await getRoutePolyline({
        state: this.state,
        orderDestinationPoints: this.props.order.destinationPoints,
        setState: (items) => this.setState(items),
        polylineErrorHandler: this.polylineErrorHandler,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const orderStage = Number(parseLocalStorageItem("orderStage"));
    const passedPoints = parseLocalStorageItem("passedPoints");
    const driverPauseCoords = parseLocalStorageItem("driverPauseCoords");
    let orders = this.props.order.destinationPoints;

    if (prevState.stage !== this.state.stage) {
      this.manageCancelOrderTimer();
    }
    // inform app about finish map load
    if (prevState.mapLoaded !== this.state.mapLoaded && this.state.mapLoaded) {
      onContentLoaded();
    }

    // update current coords for driver for profile
    if (prevState.coordsDriver === null && this.state.coordsDriver) {
      const { latitude, longitude } = this.state.coordsDriver;
      updateCoords({ lat: latitude, lon: longitude });
    }

    // set driver coords when driver on pause
    if (this.state.coordsDriver && this.props.waitingTime) {
      const callBackOnBigDifference = () => {
        switch (true) {
          case orderStage === orderStages.CarArrived:
            return this.clientOn();
          default:
            return this.resumeOrderFromPause();
        }
      };
      checkPauseCoords({
        coordsDriver: this.state.coordsDriver,
        driverPauseCoords,
        maxPointsDifferenceMeters: maxPauseDistance,
        callBackOnBigDifference,
      });
    } else {
      localStorage.removeItem("driverPauseCoords");
    }

    if (this.props.closeOrder || this.props.canceledOrder) {
      this.props.navigate("/orders");
    }

    if (this.state.stage !== prevState.stage) {
      // get polylinePositions
      await getRoutePolyline({
        state: this.state,
        orderDestinationPoints: this.props.order.destinationPoints,
        setState: (items) => this.setState({ ...items }),
        polylineErrorHandler: this.polylineErrorHandler,
      });
    }

    this.getTimeDuretion();
    const timeDuration = waitingLS.getCurrentTimeTrip();
    if (this.state.timeDurations === null && timeDuration) {
      this.setState({ timeDurations: timeDuration });
    }

    // if we ook new coords, but before we doesn't have
    if (prevState.coordsDriver === null && this.state.coordsDriver !== null) {
      await getRoutePolyline({
        state: this.state,
        orderDestinationPoints: this.props.order.destinationPoints,
        setState: (items) => this.setState(items),
        polylineErrorHandler: this.polylineErrorHandler,
      });
    }

    if (passedPoints) {
      // filter passed points
      orders = orders.filter(
        (item1) => !passedPoints.some((item2) => item1.lat === item2[0] && item1.lon === item2[1]),
      );
    }

    if (orders && orders.length && JSON.stringify(this.state.currentAdress) !== JSON.stringify(orders[0])) {
      this.setState({ currentAdress: orders[0] });
    }

    // Показываем модальное окно, если orderStatusId !== текушему этапу заказа
    if (
      prevProps.statusIdNotCorrect !== this.props.statusIdNotCorrect &&
      this.props.statusIdNotCorrect === CURRENT_ORDER_STATUS_CHECK_MAX_ATTEMPTS
    ) {
      this.setState({
        mainAlertText: t("there_was_some_glitch_please_try_again"),
        isCorrectStatusId: false,
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
    }

    if (this.props.waitingTime && this.state.paidWait === null) {
      localStorage.setItem("paidWait", 1);
      const currentTimeUnix = Math.floor(Date.now() / 1000);
      localStorage.setItem("startTimer", currentTimeUnix);
      this.setState({
        paidWait: 1,
      });
      this.setState({
        orderIconStage: 2,
      });
    }

    if (this.props.statusIdNotCorrect !== 5 && prevState.stage < orderStage) {
      if (prevProps.isErrorOrder !== this.props.isErrorOrder) {
        this.setState({
          mainAlertText: t("failed_process_order"),
          isCorrectStatusId: false,
        });
        let status = !this.props.showMAlert;
        this.props.showModalAlert(status);
      }

      if (prevState.stage < orderStage) {
        this.setState({
          stage: orderStage,
          isCorrectStatusId: false,
        });
      }
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("pushOrderAccepted");
    localStorage.removeItem("paidWait");
    localStorage.removeItem("startTimer");
    this.props.checkPushOrderAccepted();
    clearInterval(this.distanceInterval);
    clearInterval(this.coordsCheckInterval);
    // clearInterval(this.coordsInOrderRadiusInterval);
    clearTimeout(this.cancelOrderTimer);
  }

  setArrivedDriverCoords = () => {
    const { order } = this.props;
    const distance = checkArrivalDistance(order);
    const allowArrivedCoords = localStorage.getItem("allowArrivedCoords")
      ? JSON.parse(localStorage.getItem("allowArrivedCoords"))
      : [];

    const driverCoordsToString = JSON.stringify(this.state.coordsDriver);

    if (
      distance <= minArrivalDistanceMeters &&
      allowArrivedCoords.length < 5 &&
      !allowArrivedCoords.includes(driverCoordsToString)
    ) {
      localStorage.setItem(
        "allowArrivedCoords",
        JSON.stringify([...allowArrivedCoords, driverCoordsToString]),
      );
      return;
    }
  };

  isWithinArrivalRadius = () => {
    const { order } = this.props;

    const driverCoords = parseLocalStorageItem("coords") || {};

    if (!order.destinationPoints[0].lon || !order.destinationPoints[0].lat) {
      return false;
    }

    const driverPosition = [driverCoords.longitude, driverCoords.latitude];
    const clientPosition = [order.destinationPoints[0].lon, order.destinationPoints[0].lat];
    const distance = calculateHaversineDistance(driverPosition, clientPosition);

    return distance < allowArriveRadiusInMeters;
  };

  checkArrivalDistance = () => {
    const { order, showModalAlert } = this.props;

    const gpsSignalBlockedLocalStore = parseLocalStorageItem("gpsSignalBlocked");
    // const allowArrivedCoords = parseLocalStorageItem("allowArrivedCoords") || [];
    const preOrderTimeStatus = getRemainingTimeOrWithinLimit(order.preOrderTime);

    switch (true) {
      // case allowArrivedCoords.length !== 5 && !gpsSignalBlockedLocalStore && !this.isWithinArrivalRadius():
      case !gpsSignalBlockedLocalStore && !this.isWithinArrivalRadius():
        this.setState({
          mainAlertText: t("you_have_not_reached_required_point"),
        });
        showModalAlert(true);
        break;

      case typeof preOrderTimeStatus === "string" && order.preOrderTime:
        this.setState({
          mainAlertText: t("arrival_time_notification", { time: preOrderTimeStatus }),
        });
        showModalAlert(true);
        break;

      default:
        this.inPlace();
    }
  };

  Menu = () => {
    let showM = !this.props.showmenu;
    this.props.showMenu(showM);
  };

  inPlace = () => {
    let orderId = this.props.order.orderId;

    this.setState({
      isCorrectStatusId: true,
      orderIconStage: 1,
    });

    if (!orderId) {
      this.props.navigate("/orders");
      localStorage.removeItem("orderStage");
      localStorage.setItem("readyForOrder", true);
    } else {
      this.props.currentOrderInfo(this.props.navigate, 1, 4);
      this.props.arriveOrder(orderId);
      localStorage.removeItem("orderShowRequestBody");

      // set driver coords, when he arrived
      const coordsDriver = parseLocalStorageItem("coords");
      localStorage.setItem("driverPauseCoords", JSON.stringify(coordsDriver));

      const lastI = this.props.order.destinationPoints.length - 1;
      const currentAdress = this.props.order.destinationPoints[lastI];
      this.setState({
        currentAdress: currentAdress,
      });
      localStorage.setItem("currentAdress", JSON.stringify(currentAdress));
    }
  };

  prepearToNewOrder = () => {
    clearIncomeOrder();
    this.props.clearOrder();
    localStorage.setItem("readyForOrder", true);
    this.props.navigate("/orders");
  };

  rejectOrd = async () => {
    const orderId = this.props.order.orderId;
    this.prepearToNewOrder();
    if (!orderId) {
      return;
    } else {
      const data = {
        orderId,
      };

      await this.props.rejectOrder(data);
    }
  };

  rejectOrderConfirm = () => {
    this.setState({
      mainConfirmText: t("do_you_want_cancel_order"),
    });
    this.props.showModalConfirm(true);
  };

  getTimeDuretion = () => {
    if (this.state.stage !== 4) {
      return;
    }
    if (this.state.isGetForMap) {
      return;
    }
    const currentTimeTrip = waitingLS.getCurrentTimeTrip();
    if (currentTimeTrip) {
      return this.setState({ isGetForMap: true });
    }
  };

  clientOn = () => {
    const currentTimeUnix = Math.floor(Date.now() / 1000);
    waitingLS.setStartTimeWork(currentTimeUnix);

    // change waiting for time
    const lastTime = localStorage.getItem("globalPaidWait");
    const currentTime = localStorage.getItem("paidWait");
    const time = +currentTime + +lastTime;
    localStorage.setItem("globalPaidWait", time);
    //change address in header; for now get second address. TODO: need to change the text of the address during the trip

    let orderId = this.props.order.orderId;
    this.props.paidWaiting(this.props.order.orderId, "stop");
    this.props.checkWaitingTime(false);

    localStorage.removeItem("paidWait");
    localStorage.removeItem("startTimer");
    localStorage.removeItem("orderShowRequestBody");

    // route to first point set to passed rout
    localStorage.setItem("passedRoute", JSON.stringify(this.state.polylinePositions));

    // set passed first point
    const firstPoint = this.props.order.destinationPoints[0];
    localStorage.setItem("passedPoints", JSON.stringify([[firstPoint.lat, firstPoint.lon]]));

    this.setState({
      orderIconStage: 1,
      paidWait: null,
      addressPointIndex: 1,
      isCorrectStatusId: true,
      zoomOnClientOn: true,
    });

    if (!orderId) {
      this.props.navigate("/orders");
      localStorage.removeItem("orderStage");
      localStorage.setItem("readyForOrder", true);
    } else {
      this.props.checkWaitingTime(false);
      this.props.currentOrderInfo(this.props.navigate, 4, 8);
      this.props.clientOnBoard(orderId);
    }
  };

  resumeOrderFromPause = () => {
    this.props.currentOrderInfo(this.props.navigate);
    this.props.paidWaiting(this.props.order.orderId, "stop");
    this.props.checkWaitingTime(false);
    this.setState({
      paidWait: null,
    });
    this.setState({
      orderIconStage: 1,
    });
    const lastTime = localStorage.getItem("globalPaidWait");
    const currentTime = localStorage.getItem("paidWait");
    const time = +currentTime + +lastTime;
    localStorage.setItem("globalPaidWait", time);
    localStorage.removeItem("paidWait");
    localStorage.removeItem("startTimer");
  };

  completeOrd = () => {
    //check if allow complete order
    const allowArrivedCoords = localStorage.getItem("allowArrivedCoords")
      ? JSON.parse(localStorage.getItem("allowArrivedCoords"))
      : [];
    if (!allowArrivedCoords.length === 5) {
      this.setState({
        mainAlertText: t("probably_have_not_reached_destination"),
      });
      let status = !this.props.showMAlert;
      this.props.showModalAlert(status);
      return;
    }

    let orderId = this.props.order.orderId;
    localStorage.removeItem("orderShowRequestBody");
    localStorage.removeItem("allowArrivedCoords");
    this.setState({
      isCorrectStatusId: true,
      orderIconStage: 3,
    });
    localStorage.removeItem("globalPaidWait"); //! if has any checking it needs to move in answer for back
    waitingLS.removeStartTimeWork();
    waitingLS.removeCurrentTimeTrip();

    // may be add this.setState({isGetForMap : false})
    this.setState({});

    this.setState({
      isCorrectStatusId: true,
    });

    if (!orderId) {
      this.props.navigate("/orders");
      localStorage.removeItem("orderStage");
      localStorage.removeItem("paidWait");
      localStorage.removeItem("startTimer");
      localStorage.setItem("readyForOrder", true);
    } else {
      this.props.currentOrderInfo(this.props.navigate, 8, 16);
      this.props.completeOrder(orderId);
    }
  };

  payed = async () => {
    const { order, navigate, currentOrderInfo, paidOrder } = this.props;
    const orderId = order.orderId;

    localStorage.removeItem("orderShowRequestBody");
    this.setState({ isCorrectStatusId: true });

    if (!orderId) {
      navigate("/orders");
      localStorage.removeItem("orderStage");
      localStorage.removeItem("currentAdress");
      localStorage.setItem("readyForOrder", true);
    } else {
      commonCleanup();

      if (order.paymentType === 1 || order.paymentType === 4) {
        this.setState({ stage: 128 });
        return;
      }

      await currentOrderInfo(navigate, 16, 128);
      await paidOrder(orderId);
    }
  };

  showDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  goFinish = () => {
    if (this.state.stage !== 128) {
      this.setState({ isCorrectStatusId: false });
      return;
    }

    if (
      this.state.paidByCard === true &&
      (this.props.order.paymentType === 1 || this.props.order.paymentType === 4)
    ) {
      this.props.paidByCard();
      this.setState({
        stage: Number(localStorage.getItem("orderStage")),
        paidByCard: "",
      });
    }
  };

  tryClearError = () => {
    this.props.clearError();
  };

  tryClearPolylineError = () => {
    this.setState({ polylineError: false });
  };

  setZoomFullRoute = (value) => {
    this.setState({
      zoomFullRoute: value,
    });
  };

  polylineErrorHandler = () => {
    this.setState({ polylineError: true });
  };

  setMapLoaded = (condition) => {
    this.setState({ mapLoaded: condition });
  };

  render() {
    const { order, closeOrder, showMAlert, canceledOrder, errorStatus, showMConfirm } = this.props;
    const coordsDriver = JSON.parse(localStorage.getItem("coords")) || null;

    const adress = this.state?.currentAdress;

    // Error modals
    const getModalError = (error) => {
      switch (error) {
        case this.state.polylineError:
          return (
            <ModalAlert
              completeIcon={true}
              confirmHandler={this.tryClearPolylineError}
              mainAlertDesc={t("coordinates_error_description")}
              mainAlertText={t("coordinates_error")}
            />
          );
        default:
          return null;
      }
    };

    const orderButton = (callBack) => (
      <OrderMainButtons
        checkArrivalDistance={this.checkArrivalDistance}
        clientOn={this.clientOn}
        completeOrd={this.completeOrd}
        isCorrectStatusId={this.state.isCorrectStatusId}
        onSetPaidWait={() => {
          this.props.paidWaiting(this.props.order.orderId, "start");
          this.setState({
            paidWait: 1,
          });
          this.setState({
            orderIconStage: 2,
          });
          localStorage.setItem("paidWait", 1);
          localStorage.getItem("globalPaidWait") === null && localStorage.setItem("globalPaidWait", 1);
          const currentTimeUnix = Math.floor(Date.now() / 1000);
          localStorage.setItem("startTimer", currentTimeUnix);
        }}
        paidWait={this.state.paidWait}
        paymentType={this.props.order.paymentType}
        rejectOrderConfirm={this.rejectOrderConfirm}
        resumeOrderFromPause={this.resumeOrderFromPause}
        stage={this.state.stage}
        wrapCallBack={callBack}
        payed={this.payed}
        onChangeDriver={async () => {
          await this.props.onChangeDriver();
          this.prepearToNewOrder();
        }}
      />
    );

    const disabledButtonsOnFinish = this.state.stage === 16;
    const isDisabledMenu = disabledButtonsOnFinish && (order.paymentType === 1 || order.paymentType === 4);

    return (
      <>
        {/* if not gps signal set manual cords for car pos, but return app to normal mode */}
        <SetManualCoords
          coordsDriver={
            this.state.coordsDriver
              ? [this.state.coordsDriver.longitude, this.state.coordsDriver.latitude]
              : null
          }
          disabledView
        />

        {(!this.state.mapLoaded || !coordsDriver) && (
          <Loader loaderText={[t("location_data_needed_for_map_view"), t("please_wait")]} showMenuSpace />
        )}

        {showMAlert ? (
          <ModalAlert confirmHandler={this.goFinish} mainAlertText={this.state.mainAlertText} />
        ) : null}
        {showMConfirm ? (
          <ModalConfirm confirmHandler={this.rejectOrd} mainConfirmText={this.state.mainConfirmText} />
        ) : null}
        {coordsDriver && (
          <>
            {this.state.polylineError && getModalError(this.state.polylineError)}
            <div className="container-fluid p-0 m-0">
              <div className="row m-0">
                <div className="order-section col-sm-12 p-0">
                  <CustomMap
                    coordsDriver={[coordsDriver.longitude, coordsDriver.latitude]}
                    disabledFunctions={{
                      disableButtons: disabledButtonsOnFinish,
                      disableGlueCar: disabledButtonsOnFinish,
                      disabledSpeed: disabledButtonsOnFinish,
                      disabledNavigation: disabledButtonsOnFinish,
                      disableInteractive: false,
                      disabled3DView: false,
                    }}
                    mapLoaded={this.state.mapLoaded}
                    mapPoints={this.state.mapPoints}
                    onClickMapProvBtn={() =>
                      this.setState({
                        isOpenMoreOrder: false,
                      })
                    }
                    onMapProviderBtn={() => {
                      this.setState({
                        showDetails: false,
                      });
                    }}
                    onNavigationClick={(value) => this.setState({ showDetails: value })}
                    polylinePositions={this.state.polylinePositions}
                    setMapLoaded={this.setMapLoaded}
                    setZoomFullRoute={this.setZoomFullRoute}
                    speed={this.state.speed}
                    zoomFullRoute={this.state.zoomFullRoute}
                  />

                  {this.state.stage === 128 && <ReviewOrder order={order} />}
                  {this.state.paidWait ? (
                    <div className="timer">
                      <TimerPaid waitingTime={this.state.paidWait} />
                    </div>
                  ) : null}
                  <div className="bottom-section">
                    <OrderInfoMenu
                      adress={adress}
                      buttonForOrderMenu={orderButton}
                      coordsDriver={coordsDriver}
                      currentAdress={this.state.currentAdress}
                      isOpenMoreOrder={this.state.isOpenMoreOrder}
                      onDownLineClick={this.showDetails}
                      onHeaderClick={() =>
                        this.setState({
                          isOpenMoreOrder: !this.state.isOpenMoreOrder,
                        })
                      }
                      order={order}
                      orderIconStage={this.state.orderIconStage}
                      paidWait={this.state.paidWait}
                      showDetails={this.state.showDetails}
                      stage={this.state.stage}
                      timeDurations={this.state.timeDurations}
                      waitingLS={waitingLS}
                    />
                    {orderButton()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <BottomMenu isDisabled={isDisabledMenu} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.order.order,
    statusIdNotCorrect: state.order.statusIdNotCorrect,
    showmenu: state.menu.showmenu,
    closeOrder: state.order.closeOrder,
    showMConfirm: state.modalConfirm.showMConfirm,
    acceptOrder: state.order.acceptOrder,
    //    orderIdForPay: state.order.orderIdForPay,
    showMAlert: state.modalAlert.showMAlert,
    canceledOrder: state.order.canceledOrder,
    liveOrder: state.order.liveOrder,
    errorStatus: state.order.errorStatus,
    errorMsg: state.order.errorMsg,
    appCoords: state.app.coords,
    waitingTime: state.timer.waitingTime,
    isErrorOrder: state.order.isErrorOrder,
    orderPos: state.order.orderPos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearOrder: () => dispatch(clearOrder()),
    showMenu: (showM) => dispatch(showMenu(showM)),
    rejectOrder: (data) => dispatch(rejectOrder(data)),
    arriveOrder: (orderId) => dispatch(arriveOrder(orderId)),
    clientOnBoard: (orderId) => dispatch(clientOnBoard(orderId)),
    completeOrder: (orderId) => dispatch(completeOrder(orderId)),
    paidOrder: (orderId) => dispatch(paidOrder(orderId)),
    showModalConfirm: (status) => dispatch(showModalConfirm(status)),
    sendOrderPoint: (pos) => dispatch(sendOrderPoint(pos)),
    showModalAlert: (status) => dispatch(showModalAlert(status)),
    paidByCard: () => dispatch(paidByCard()),
    clearError: () => dispatch(clearError()),
    paidWaiting: (orderId, type) => dispatch(paidWaiting({ orderId, type })),
    checkWaitingTime: (data) => dispatch(checkWaitingTime(data)),
    checkPushOrderAccepted: () => dispatch(checkPushOrderAccepted()),
    currentOrderInfo: (routsHistory, statusId, nextStatusId, retryOnError) =>
      dispatch(currentOrderInfo(routsHistory, statusId, nextStatusId, retryOnError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(withRTKQuery(Order)));
