import React, { useEffect, useState } from "react";

import { useGetDailyAttemptQuery, useGetPolygonsByRegionCodeQuery } from "../api/filterByPolygonsQuery";
import { useLazyGetPointsDynamicPriceQuery } from "../api/pointsDynamicPrice";
import { dynamicPriceIntervalTime } from "../constants";

function withRTKQuery(WrappedComponent) {
  return function WithRTKQuery(props) {
    const { profInfo } = props;
    const [dynamicPriceFromRtkHok, setDynamicPriceFromRtkHok] = useState(null);
    const [trigger] = useLazyGetPointsDynamicPriceQuery();

    let dailyAttemptFromRtkHok = null;
    let polygonsFromRtkHok = null;

    const { data: polygons } = useGetPolygonsByRegionCodeQuery(
      {
        regionId: profInfo.regionId,
      },
      { skip: !profInfo.regionId },
    );
    const { data: dailyAttempt } = useGetDailyAttemptQuery({}, { refetchOnMountOrArgChange: true });

    dailyAttemptFromRtkHok = dailyAttempt;
    polygonsFromRtkHok = polygons;

    // get dynamic price for regions by timer
    useEffect(() => {
      const driverCoordsString = localStorage.getItem("coords");
      const driverCoords = driverCoordsString ? JSON.parse(localStorage.getItem("coords")) : null;
      if (props?.cars && props.cars.length > 0 && driverCoords) {
        const carClassId = props?.cars.find((item) => item.isDefault).classId;
        const getDynamicPrice = async () => {
          const response = await trigger({
            carClassId: carClassId,
            lat: driverCoords.latitude,
            lon: driverCoords.longitude,
          });
          setDynamicPriceFromRtkHok(response?.data?.data || null);
        };
        getDynamicPrice();
        const dynamicPriceInterval = setInterval(getDynamicPrice, dynamicPriceIntervalTime);

        return () => {
          clearInterval(dynamicPriceInterval);
        };
      }
    }, [props.cars, trigger]);

    return (
      <WrappedComponent
        {...props}
        dailyAttemptFromRtkHok={dailyAttemptFromRtkHok}
        dynamicPriceFromRtkHok={dynamicPriceFromRtkHok}
        polygonsFromRtkHok={polygonsFromRtkHok}
      />
    );
  };
}

export { withRTKQuery };
