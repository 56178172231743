import { calculateDistance } from "./calculateDistance";

export const checkArrivalDistance = (order) => {
  const coordsDriver = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : null;
  if (coordsDriver) {
    const clientLat = order.destinationPoints[0].lat;
    const clientLon = order.destinationPoints[0].lon;

    const distance = calculateDistance(coordsDriver.latitude, coordsDriver.longitude, clientLat, clientLon);

    return distance;
  }
};
