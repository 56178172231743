import { useCallback, useEffect, useRef } from "react";
import maplibregl from "!maplibre-gl";
import { findNearestPoint } from "../helpers/findSegment";

const createRedPointElement = () => {
	const redPointEl = document.createElement("div");
	redPointEl.className = "redPoint";
	return redPointEl;
};

const useCreatePoints = (props) => {
	const {
		map,
		mapPoints,
		mapLoaded,
		polylineWithDividePoint,
		coordsDriver,
		showRedMarker,
	} = props;

	const markersRef = useRef([]);

	useEffect(() => {
		if (
			(map.current,
			mapPoints && mapLoaded && polylineWithDividePoint && coordsDriver)
		) {
			const passedPoints = localStorage.getItem("passedPoints")
				? JSON.parse(localStorage.getItem("passedPoints"))
				: [];

			// Get passed points from localStorage or initialize as an empty array
			const passedRouteStore = localStorage.getItem("passedRoute")
				? JSON.parse(localStorage.getItem("passedRoute"))
				: [];

			// Combine passedRouteStore and polylineWithDividePoint into allPolyPoints
			const allPolyPoints = [...passedRouteStore, ...polylineWithDividePoint];

			// Get the last item index for mapPoints
			const lastItemIndx = mapPoints.length - 1;
			const bigPoints = [0, lastItemIndx];

			let passedPoint = null;

			mapPoints.forEach((item, index) => {
				const nearPoint = findNearestPoint(allPolyPoints, [
					item.lat,
					item.lon,
				])?.point;

				const correctDesPoints = [mapPoints[index].lat, mapPoints[index].lon];

				const doesNotInclude = !passedPoints.some((coords) =>
					coords.every(
						(coord, innerIndex) => coord === correctDesPoints[innerIndex],
					),
				);

				if (nearPoint) {
					let driverPos = new maplibregl.LngLat(
						coordsDriver[0],
						coordsDriver[1],
					);
					let nearPointPos = new maplibregl.LngLat(nearPoint[1], nearPoint[0]);

					const distance = driverPos.distanceTo(nearPointPos);

					if (distance < 10 && doesNotInclude) {
						passedPoint = [mapPoints[index].lat, mapPoints[index].lon];
					}
				}

				let allPassedPoints = passedPoints;

				if (passedPoint) {
					allPassedPoints = [...passedPoints, passedPoint];
					localStorage.setItem("passedPoints", JSON.stringify(allPassedPoints));
				}

				// marker html elements
				const pointEl = showRedMarker
					? createRedPointElement()
					: document.createElement("div");
				const innerPointEl = !showRedMarker && document.createElement("div");

				// bigger points style
				const isBigPoints = bigPoints.includes(index);
				const isPassedPoint = allPassedPoints.some(
					(point) =>
						JSON.stringify(point) === JSON.stringify([item.lat, item.lon]),
				);

				if (!showRedMarker) {
					pointEl.className = `${isBigPoints ? "pointBig " : ""}${
						isPassedPoint ? "grayPoint" : "blackPoint"
					}`;

					// its must be last point
					if (index === lastItemIndx && index != 0) {
						pointEl.className = "pickup-point-marker";
					}

					innerPointEl.className = `${
						isBigPoints ? "innerPointBig " : ""
					}innerBlackPoint`;

					innerPointEl.innerHTML = !isBigPoints ? `${index + 1}` : "";
					pointEl.appendChild(innerPointEl);
				}

				if (!nearPoint) return;

				const marker = new maplibregl.Marker({
					element: pointEl,
					rotationAlignment: "map",
					pitchAlignment: "map",
				}).setLngLat([nearPoint[1], nearPoint[0]]);

				const currentMarker = markersRef.current.find(
					(marker) => marker.name === item.name,
				);

				const currentMarkerIndx = markersRef.current.findIndex(
					(marker) => marker.name === item.name,
				);

				// cleanup points
				if (currentMarker) {
					currentMarker.markerEl.remove();

					markersRef.current[currentMarkerIndx] = {
						name: item.name,
						markerEl: marker,
					};
				} else {
					markersRef.current = [
						...markersRef.current,
						{ name: item.name, markerEl: marker },
					];
				}

        marker.addTo(map.current);
      });
    } else {
      // Remove all existing markers
      if (markersRef.current) {
        markersRef.current.forEach((marker) => {
          marker.markerEl.remove();
        });
      }
    }
  }, [mapPoints, mapLoaded, polylineWithDividePoint, coordsDriver, map]);
};

export default useCreatePoints;
