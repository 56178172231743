import { memo } from "react";
import styles from "./styles.module.scss";
import { Trans } from "react-i18next";

const EtherOrderHeaderInfo = ({ etherOrderStatistics }) => {
  return (
    <div className={styles.container}>
      <div className={styles.circle} />
      <div className={styles.infoOrder}>
        <span className={styles.title}>
          <Trans
            components={{
              Bold: <span className={styles.count} />,
            }}
            i18nKey="executed_live_order"
            values={{ count: etherOrderStatistics?.ongoingCount }}
          />
        </span>
      </div>
    </div>
  );
};

export default memo(EtherOrderHeaderInfo);
