import React from "react";
import { BlackButton } from "src/shared/ui/Buttons/BlackButton";

import styles from "./styles.module.scss";
import ModalNew from "src/pages/Modals/ModalNew";
import { t } from "i18next";

const AddressNotFoundPopup = ({ onClick, showAddressPopup }) => {
  const popupInner = (
    <div className={styles.manualCoordsPopup}>
      <h2>{t("no_results_for_specified_address")}</h2>
      <BlackButton className={styles.confirmBtn} onClick={onClick}>
        {t("close")}
      </BlackButton>
    </div>
  );
  return (
    <ModalNew
      customInnerComponent={popupInner}
      newModalWrapClassName={`${styles.alertPopupWrap} ${showAddressPopup ? styles.alertPopupWrapActive : ""}`}
    />
  );
};

export default AddressNotFoundPopup;
