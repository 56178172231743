import { axiosInstanceWithToken } from "src/axios.config";
import { gpsStatus } from "src/config";

export const getGpsStatus = async () => {
  try {
    const response = await axiosInstanceWithToken.get(gpsStatus);
    return response;
  } catch (error) {
    console.log("error in getGpsStatus: ", error);
  }
};
