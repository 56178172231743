import { useEffect, useRef } from "react";

const useInterval = (callback, delay, runImmediately) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;

    if (runImmediately) {
      savedCallback.current();
    }
  }, [callback, runImmediately]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
};

export default useInterval;
