// NOT DELETE, its for older version webview
import "globalthis/auto";
import "core-js/stable";
//

import React from "react";
import ReactDOM from "react-dom/client";

import App from "src/app/App";
import Providers from "src/app/providers";

import "src/i18n/config";
import "src/styles/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Providers>
    <App />
  </Providers>,
);
