import React, { memo, useCallback, useState } from "react";
import { t } from "i18next";
import Snackbar from "@mui/material/Snackbar";

import useDebounce from "src/hooks/useDebounce";
import useClickOutside from "src/hooks/useClickOutside";

import { fetchSuggestions } from "src/shared/api/fetchSuggestions";
import { fetchGeocodeFromCoords } from "src/shared/api/fetchGeocodeFromCoords";

import MainManualCoordsView from "../MainManualCoordsView";
import ManualCoordsPopup from "../MainManualCoordsView/components/ManualCoordsPopup";
import { onManualLocationChanged } from "src/shared/funcForMobile";
import AddressNotFoundPopup from "../MainManualCoordsView/components/AddressNotFoundPopup";

import { ReactComponent as CheckCircle } from "src/images/check_circle.svg";
import { ReactComponent as Settings } from "src/images/settings.svg";

import styles from "./styles.module.scss";
import { defaultStatusBarHeight } from "src/shared/constants";

const SetManualCoordsView = ({ coordsDriver }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [showMainView, setShowMainView] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [markerCenterCoords, setMarkerCenterCoords] = useState(coordsDriver);
  const [externalMap, setExternalMap] = useState(null);
  const [address, setAddress] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showAddressList, setShowAddressList] = useState(false);
  const [allowAddressByCoords, setAllowAddressByCoords] = useState(false);
  const [allowValidateAddress, setAllowValidateAddress] = useState(true);
  const [showAcceptCoords, setShowAcceptCoords] = useState(false);
  const [showReturnToGps, setShowReturnToGps] = useState(false);
  const [isActiveManualMod, setIsActiveManualMod] = useState(
    localStorage.getItem("isActiveManualMod") ? JSON.parse(localStorage.getItem("isActiveManualMod")) : false,
  );
  const alertPopupRef = useClickOutside(() => setShowAlertPopup(false));

  const showAlertButton = !showMainView && !showAlertPopup;

  const disabledMapFunctions = {
    disableSeparateRoute: true,
    disableGlueCar: true,
    disabledSpeed: true,
    disabledNavigation: true,
    disabledCarMarker: true,
    disableCircle: true,
    disabledMarkerInCenter: false,
  };

  // map object from custom map component
  const callBackReturnMap = (map) => {
    setExternalMap(map?.current);
  };

  // props for included map
  const mapProps = {
    disabledFunctions: disabledMapFunctions,
    coordsDriver,
    mapLoaded,
    setMapLoaded,
    setMarkerCenterCoords,
    callBackReturnMap,
  };

  // on open MainManualCoordsView set map center
  const setMapCenter = (coords) => {
    if (externalMap) {
      externalMap.setCenter(coords);
    }
  };

  // set address, show address list and mark we are typing
  const onInputChange = async (event) => {
    setAddress(event?.target?.value || "");
    setIsTyping(true);
    setShowAddressList(true);
    setAllowValidateAddress(true);
  };

  // hidden list and set address
  const onClickItemInList = (addressItem) => {
    setShowAddressList(false);
    setAllowAddressByCoords(false);
    setAddress(addressItem.address);
    setMarkerCenterCoords([addressItem.longitude, addressItem.latitude]);
    setMapCenter([addressItem.longitude, addressItem.latitude]);
  };

  // on click popup centered map by current coords, clear prev search list
  const onManualPopupAccept = () => {
    setMapCenter(coordsDriver);
    setShowAlertPopup(false);
    setShowMainView(true);
    setAllowAddressByCoords(true);
    setAddressList([]);
  };

  // deactivate manual gps mod for app, not backend
  const returnToGpsMode = () => {
    localStorage.setItem("isActiveManualMod", false);
    setIsActiveManualMod(false);
    setShowAlertPopup(false);
    setShowReturnToGps(true);
    // deactivate mode for mobile app
    onManualLocationChanged(null);
  };

  // get address list by coords and text ib input
  const getAddressList = useCallback(async () => {
    if (address) {
      const userLocation = { lat: markerCenterCoords[1], lon: markerCenterCoords[0] };
      const languageName = localStorage.getItem("language_name");
      const { data } = await fetchSuggestions({
        addressQuery: address,
        userLocation,
        autocomplete: true,
        languageName,
      });

      setAddressList(data);
    } else {
      setAddressList([]);
    }

    setIsTyping(false);
  }, [address, markerCenterCoords]);

  useDebounce({ callback: getAddressList, condition: isTyping, duration: 500 });

  // get address by coords
  const getAddressByCoords = useCallback(async () => {
    setShowAddressList(false);
    const point = { lat: markerCenterCoords[1], lon: markerCenterCoords[0] };
    const languageName = localStorage.getItem("language_name");
    const { data } = await fetchGeocodeFromCoords({ point, languageName });
    setAllowValidateAddress(false);
    setAddress(data);
  }, [markerCenterCoords]);

  useDebounce({ callback: getAddressByCoords, condition: allowAddressByCoords, duration: 700 });

  // before set address validate
  const validateAddress = () => {
    switch (true) {
      // if address empty, took last location
      case !address.length:
        getAddressByCoords();
        return false;
      // if not address in address list
      case addressList.length === 0:
        setShowAddressPopup(true);
        return false;
      // set first address from list
      case !addressList.some((addressItem) => addressItem.address === address):
        onClickItemInList(addressList[0]);
        return false;
      default:
        return true;
    }
  };

  // submit address and set it to local store
  const onSubmitAddress = () => {
    if (allowValidateAddress && !validateAddress()) {
      return;
    }

    const [longitude, latitude] = markerCenterCoords;
    const localStoreCoords = localStorage.getItem("coords") ? JSON.parse(localStorage.getItem("coords")) : {};
    localStorage.setItem("coords", JSON.stringify({ ...localStoreCoords, longitude, latitude }));
    onManualLocationChanged(JSON.stringify({ lon: longitude, lat: latitude }));
    setShowMainView(false);
    // top modal
    setShowAcceptCoords(true);

    // activate manual mod for frontend
    localStorage.setItem("isActiveManualMod", true);
    setIsActiveManualMod(true);
  };

  // button content on active manual mode
  const activeManualModeButtonInner = (
    <>
      <div className={styles.greenRound} />
      {t("manual_mode")}
      <Settings />
    </>
  );

  // button content on normal gps mode
  const defaultModeButtonInner = (
    <>
      {t("gps_problems")}
      <span className={styles.questionSymbol}>?</span>
    </>
  );

  return (
    <>
      {/* Show top popup about return to normal gps mode */}
      <Snackbar
        action={
          <div className={styles.acceptCoordsSnackBarContent}>
            <CheckCircle />
            {t("your_location_with_gps")}
          </div>
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        className={styles.acceptCoordsSnackBar}
        onClick={() => setShowReturnToGps(false)}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setShowReturnToGps(false);
        }}
        open={showReturnToGps}
        style={{ marginTop: `${localStorage.getItem("statusBarHeight") || defaultStatusBarHeight}px` }}
      />
      {/* Show top popup about update location */}
      <Snackbar
        action={
          <div className={styles.acceptCoordsSnackBarContent}>
            <CheckCircle />
            {t("gps_changed")}
          </div>
        }
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        className={styles.acceptCoordsSnackBar}
        onClick={() => setShowAcceptCoords(false)}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setShowAcceptCoords(false);
        }}
        open={showAcceptCoords}
        style={{ marginTop: `${localStorage.getItem("statusBarHeight") || defaultStatusBarHeight}px` }}
      />
      {/* bottom popup with two buttons */}
      <ManualCoordsPopup
        isActiveManualMod={isActiveManualMod}
        onClick={onManualPopupAccept}
        onReturnToGps={returnToGpsMode}
        onSecondButtonClick={() => setShowAlertPopup(false)}
        ref={alertPopupRef}
        showAlertPopup={showAlertPopup}
      />
      {/* Show error popup if address not fount */}
      <AddressNotFoundPopup onClick={() => setShowAddressPopup(false)} showAddressPopup={showAddressPopup} />
      {/* button for open manual location */}
      <button
        className={`${styles.manualCoordsWrapBtn} ${showAlertButton ? styles.manualCoordsWrapBtnActive : ""}`}
        onClick={() => {
          setShowAlertPopup(true);
        }}
      >
        {isActiveManualMod ? activeManualModeButtonInner : defaultModeButtonInner}
      </button>
      {/* Map with point for set location */}
      <MainManualCoordsView
        address={address}
        addressList={addressList}
        mapProps={mapProps}
        mapWrapPops={{
          onTouchMove: () => {
            setAllowAddressByCoords(true);
          },
          onMouseMove: () => {
            setAllowAddressByCoords(true);
          },
        }}
        onClear={() => {
          setAllowAddressByCoords(false);
          setIsTyping(false);
          onInputChange(null);
        }}
        onClickItemInList={onClickItemInList}
        onInputChange={onInputChange}
        onReturnButton={() => setShowMainView(false)}
        onSubmit={onSubmitAddress}
        showAddressList={showAddressList}
        wrapClassName={`${styles.mainViewWrapClass} ${showMainView ? styles.mainViewWrapClassActive : ""}`}
      />
    </>
  );
};

export default memo(SetManualCoordsView);
