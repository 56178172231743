import { useEffect } from "react";
import { ReactComponent as DistanceIcon } from "src/images/distance-icon.svg";
import { ReactComponent as MoneyCard } from "src/images/money-card.svg";
import { ReactComponent as MoneyCash } from "src/images/money-cash.svg";
import { ReactComponent as PriceIcon } from "src/images/price-icon.svg";
import SortingButton from "./components/SortingButton";
import styles from "./styles.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";
import { etherSortOptions } from "src/shared/constants";
import { useTranslation } from "react-i18next";

const sortingButtonsData = [
  { type: etherSortOptions.ALL, Icon: null, label: "all" },
  { type: etherSortOptions.CASH, Icon: MoneyCash, label: "cash" },
  { type: etherSortOptions.CARD, Icon: MoneyCard, label: "card" },
];

const EtherOrdersDataControl = ({ setFilterType, setSortType, filterType, sortType }) => {
  const { t } = useTranslation();

  const handleFilter = (filter) => {
    setFilterType(filter);
  };

  const handleSort = (sort) => {
    const sortOrder = sortType.sortOrder === "asc" ? "desc" : "asc";
    setSortType({ sortBy: sort, sortOrder });
  };

  useEffect(() => {
    const updateLocalStorage = () => {
      const dataToSave = {
        filterType,
        sortType,
      };

      localStorage.setItem("orderEtherFilters", JSON.stringify(dataToSave));
    };

    updateLocalStorage();
  }, [filterType, sortType]);

  return (
    <div className={styles.EtherOrdersDataControl}>
      <div className={styles.leftSide}>
        {sortingButtonsData.map((buttonProps) => (
          <SortingButton
            Icon={buttonProps.Icon}
            active={filterType === buttonProps.type}
            key={buttonProps.type}
            onClick={() => handleFilter(buttonProps.type)}
          >
            {t(buttonProps.label)}
          </SortingButton>
        ))}
      </div>

      <div className={styles.rightSide}>
        <DistanceIcon
          className={classNames(
            styles.sortIcon,
            { [styles.sortByDistance]: sortType.sortBy === "distanceToOrder" },
            [],
          )}
          onClick={() => handleSort("distanceToOrder")}
        />
        <PriceIcon
          className={classNames(
            styles.sortIcon,
            { [styles.sortByPrice]: sortType.sortBy === "priceEstimated" },
            [],
          )}
          onClick={() => handleSort("priceEstimated")}
        />
      </div>
    </div>
  );
};

export default EtherOrdersDataControl;
