import React from "react";

import { ReactComponent as DoubleArrowDark } from "src/images/DoubleArrowDark.svg";

import styles from "./styles.module.scss";

interface IProps {
  highDemandColor?: string;
}

const defaultColor = "#ffff"

const HighDemandColor = (props: IProps) => {
  const { highDemandColor = null } = props;
  const color = highDemandColor || defaultColor;

  return (
    <div className={styles.orderHeaderDoubleArrowWrap}>
      <DoubleArrowDark
        className={styles.orderHeaderDoubleArrow}
        style={{ fill: color, stroke: color }}
      />
    </div>
  );
};

export default HighDemandColor;
