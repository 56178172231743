import React from "react";

const SoundOnNewEther = (props) => {
  const { playEtherOrder, setPlayEtherOrder, playSrc } = props;

  if (!playEtherOrder || !playSrc) {
    return null;
  }

  return (
    playEtherOrder && (
      <audio
        autoPlay
        onEnded={() => {
          setPlayEtherOrder(false);
        }}
        src={playSrc}
      />
    )
  );
};

export default SoundOnNewEther;
