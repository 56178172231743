import { EtherOrderActionsType } from "./etherReducer";

export const isFetchingEtherOrders = (isLoading) => {
	return {
		type: EtherOrderActionsType.FETCH_ETHER_ORDERS,
		payload: isLoading,
	};
};

export const setEtherOrders = (data) => {
	return {
		type: EtherOrderActionsType.SET_ETHER_ORDERS,
		payload: data,
	};
};

export const setFilterType = (filter) => {
	return {
		type: EtherOrderActionsType.SET_FILTER_TYPE,
		payload: filter,
	};
};

export const setSortType = (sort) => {
	return {
		type: EtherOrderActionsType.SET_SORT_TYPE,
		payload: sort,
	};
};

export const setTotalCountOrders = (count) => {
	return {
		type: EtherOrderActionsType.SET_TOTAL_COUNT_ORDERS,
		payload: count,
	};
};

export const setDeletedEtherOrderIds = (id) => {
	return {
		type: EtherOrderActionsType.SET_DELETED_ORDER_IDS,
		payload: id,
	};
};

export const setIsNewEtherOrders = (isNew) => {
	return {
		type: EtherOrderActionsType.SET_IS_NEW_ETHER_ORDERS,
		payload: isNew,
	};
};

export const setEtherOrderStatistics = (data) => {
	return {
		type: EtherOrderActionsType.SET_ETHER_ORDER_STATISTICKS,
		payload: data,
	};
};
