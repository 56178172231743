const toRadians = degrees => {
  return degrees * (Math.PI / 180)
}

const calculateDistance = (driverLat, driverLon, riderLat, riderLon) => {
  const earthRadius = 6371 // радиус Земли в километрах

  // Переводим координаты в радианы
  const radianLat1 = toRadians(driverLat)
  const radianLon1 = toRadians(driverLon)
  const radianLat2 = toRadians(riderLat)
  const radianLon2 = toRadians(riderLon)

  // Разность широт и долгот
  const diffLat = radianLat2 - radianLat1
  const diffLon = radianLon2 - radianLon1

  // Формула гаверсинусов
  const a =
    Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
    Math.cos(radianLat1) *
      Math.cos(radianLat2) *
      Math.sin(diffLon / 2) *
      Math.sin(diffLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  // Расстояние в километрах
  const distance = earthRadius * c

  // Конвертируем расстояние в метры
  const distanceInMeters = distance * 1000

  return Math.ceil(distanceInMeters)
}

export { calculateDistance }
