import React, { memo } from "react";
import { ReactComponent as ClockIcon } from "src/images/clock-v2.svg";
import styles from "./styles.module.scss";
import { Trans } from "react-i18next";
import { formatTimeToHourMinute } from "src/services/timer/formatTimeToHourMinute";

const EtherServingAuto = ({ preOrderTime }) => {
  return (
    <section className={styles.etherServingAuto}>
      <div className={styles.leftSide}>
        <ClockIcon className={styles.icon} />
        <span>
          <Trans
            components={{
              1: <span className={styles.time} />,
            }}
            i18nKey="give_car"
            values={{ time: formatTimeToHourMinute(preOrderTime) }}
          />
        </span>
      </div>
    </section>
  );
};

export default memo(EtherServingAuto);
