import { axiosInstance, axiosInstanceWithToken } from "../../axios.config";
import { api, apiImg } from "../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const SHOW_UPF = "SHOW_UPF";
export const SET_IMG_DATA = "SET_IMG_DATA";
export const DRIVER_PHOTO = "DRIVER_PHOTO";
export const DRIVER_LIC_F = "DRIVER_LIC_F";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const showUpdPassForm = (showUPF) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_UPF,
      payload: showUPF,
    });
  };
};

export const updateDriverPass = createAsyncThunk(
  "auth/updateDriverPass",
  async (passData, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axiosInstance({
        method: "post",
        url: `${api}/driver/change-password`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          id: passData.id,
          password: passData.password,
          confirmPassword: passData.confirmPassword,
        },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendImg = createAsyncThunk("auth/sendImg", async (imgInfo, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstanceWithToken.post(`${apiImg}/files`, {
      name: imgInfo.name,
      fileArray: imgInfo.fileArray,
      fileTypeId: imgInfo.inputName === "photo" ? 0 : 1,
    });

    if (response?.status === 200 || response?.status === 204) {
      if (imgInfo.inputName === "photo") {
        dispatch({
          type: DRIVER_PHOTO,
          payload: `${apiImg}/download/${response?.data.data.id}`,
        });
      }
      if (imgInfo.inputName === "driverLicenseF") {
        dispatch({
          type: DRIVER_LIC_F,
          payload: `${apiImg}/download/${response?.data.data.id}`,
        });
      }
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const CLEAR_ADD_IMG_STATUS = "CLEAR_ADD_IMG_STATUS";
export const clearAddImgStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ADD_IMG_STATUS,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

/*  export const GET_CITIES = 'GET_CITIES'
  export const getCities = () => {
    const accessToken = localStorage.getItem('accessToken')
    return dispatch => {
      axios.get(orderApi+'/catalog/cities', { headers: { "Authorization": `Bearer ${accessToken}` } })  // headers: { "Authorization": `Bearer ${accessToken}` }
      .then(response => {
        console.log("getCities RESPONSE:", reasponse)
          if (response?.status === 200 || response?.status === 204) {
            dispatch({
            type: GET_CITIES,
            payload: response?.data.data,
            })
          }
      })
      .catch(error => {
          console.log(error)
      })
    }
    }*/
