import { useNavigate, useLocation } from "react-router-dom";
import { defaultParamKey } from "src/shared/const/urlParams";

const useSetUrlParam = ({ paramValue, paramKey = defaultParamKey }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const setUrlParam = () => {
    const params = new URLSearchParams(location.search);
    params.set(paramKey, paramValue);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return setUrlParam;
};

export default useSetUrlParam;
