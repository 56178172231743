import { createSelector } from "reselect";
import { filterablePaymentTypes } from "src/shared/const/payment";
import { etherSortOptions } from "src/shared/constants";

const getEtherOrders = (state) => state.etherReducer.etherOrders;
const getFilterType = (state) => state.etherReducer.filterType;
const getSortType = (state) => state.etherReducer.sortType;
const getDeletedOrderIds = (state) => state.etherReducer.deletedEtherOrderIds;

const filterOrders = (orders, filterType) => {
  return filterType === etherSortOptions.ALL
    ? orders
    : orders.filter((order) => filterablePaymentTypes[filterType].includes(order.paymentTypeId));
};

const sortOrders = (orders, sortType) => {
  if (!sortType || !sortType.sortBy || !sortType.sortOrder) {
    return [...orders].sort((a, b) => a.distance - b.distance);
  }

  const { sortBy, sortOrder } = sortType;

  return [...orders].sort((a, b) => {
    const sortValueA = a[sortBy];
    const sortValueB = b[sortBy];

    return sortOrder === "asc" ? sortValueA - sortValueB : sortValueB - sortValueA;
  });
};

const filterDeletedOrders = (orders, deletedIds) => {
  return orders.filter((order) => !deletedIds.includes(order.id));
};

export const getFilteredEtherOrders = createSelector(
  [getEtherOrders, getFilterType, getSortType, getDeletedOrderIds],
  (etherOrders, filterType, sortType, deletedEtherOrderIds) => {
    const filteredOrders = filterOrders(etherOrders, filterType);

    const sortedOrders = sortOrders(filteredOrders, sortType);
    const visibleOrders = filterDeletedOrders(sortedOrders, deletedEtherOrderIds);

    return visibleOrders;
  },
);
