import React, { useEffect } from "react";
import maplibregl from "!maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const useCreateMap = ({
  map,
  mapContainer,
  mapTheme,
  coordsDriver,
  customMapZoom,
  setIsMapMoving,
  orderStage,
  disableCircle,
  geoJSONCircle,
  atwork,
  disabledFunctions,
}) => {
  useEffect(() => {
    // stops map from intializing more than once
    if (map.current) {
      return;
    }

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: mapTheme,
      center: coordsDriver,
      zoom: customMapZoom,
      attributionControl: false,
      interactive: !disabledFunctions.disableInteractive,
    });

    map.current.on("movestart", () => {
      setIsMapMoving(true);
    });

    map.current.on("zoom", () => {
      setIsMapMoving(true);
    });

    map.current.addControl(
      new maplibregl.AttributionControl({
        compact: true,
      }),
      "top-left",
    );

    // disable map rotation using right click + drag
    map.current.touchZoomRotate.disableRotation();

    // close default credent
    map.current.on("load", () => {
      const mapModalElement = document.querySelector("#modal-map");

      if (mapModalElement) {
        const attributionControlBtn = mapModalElement.querySelector(".maplibregl-ctrl-attrib-button");

        if (attributionControlBtn) {
          attributionControlBtn.click();
        }

        return;
      }

      const processAttributionControl = (elements) => {
        if (!elements) return;

        elements.forEach((element) => {
          if (element?.open) {
            document.querySelectorAll(".maplibregl-ctrl-attrib-button").forEach((button) => {
              // Simulate a click event to close the attribution control
              button.click();
            });
          }
        });
      };

      const attributionControlElements = document.querySelectorAll(".maplibregl-compact-show");

      processAttributionControl(attributionControlElements);
    });

    // NOT DELETE, FOR TESTING - set car coords on click
    // map.current.on("click", function (e) {
    //   // Get the coordinates (longitude and latitude) of the clicked point
    //   const coords = e.lngLat;
    //   localStorage.setItem(
    //     "coords",
    //     `{"latitude": ${coords.lat},"longitude": ${coords.lng},"speed":${Math.floor(Math.random() * 100)}}`,
    //   );
    //   // Log the coordinates to the console or use them as needed
    // });

    // Circle radius
    map.current.on("style.load", () => {
      if (!orderStage && !disableCircle) {
        map.current.addSource("circle-source", {
          type: "geojson",
          data: geoJSONCircle,
        });

        map.current.addLayer({
          id: "circle-layer",
          type: "fill",
          source: "circle-source",
          paint: {
            "fill-color": atwork ? "#1DB960" : "#757575",
            "fill-opacity": 0.2,
          },
        });

        map.current.addLayer({
          id: "circle-layer-outline",
          type: "line",
          source: "circle-source",
          paint: {
            "line-color": atwork ? "#1DB960" : "#9E9E9E",
            "line-width": 3,
          },
        });
      }
    });

    // Clean up the map instance when the component unmounts
    return () => {
      map.current.remove();
      map.current.off("movestart");
    };
  }, []);
  return null;
};

export default useCreateMap;
