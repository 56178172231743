import { axiosInstance, axiosInstanceWithToken } from "src/axios.config";
import { orderApi } from "src/config";
import {
  isFetchingEtherOrders,
  setEtherOrderStatistics,
  setEtherOrders,
  setTotalCountOrders,
} from "../etherActions";
import { getFilteredEtherOrders } from "../selectors/getFilteredEtherOrders";
import RouteService from "src/components/CustomMap/services/RouteService";
import { calculateDistance } from "src/services/calculateDistance";
import { maxRefetchDistance } from "../../constants";

const calculateDistanceForEther = async ({ combinedData }) => {
  const routeService = new RouteService();
  const { start, end } = routeService.createPoints(combinedData);
  const { formattedCoordinates, segmentDistances } = await routeService.createRoutes(start, end);
  const [firstDistance, ...remainingDistances] = segmentDistances;
  const sumOfRemainingDistances = remainingDistances.reduce((sum, value) => sum + value, 0);

  return { firstDistance, sumOfRemainingDistances, formattedCoordinates };
};

export const fetchEtherOrders = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(isFetchingEtherOrders(true));

      const prevEtherOrders = JSON.parse(localStorage.getItem("prevEtherOrders")) || [];
      const driverCoords = JSON.parse(localStorage.getItem("coords"));
      const { latitude, longitude } = driverCoords;

      let distanceToLastCoord = null;

      const { data } = await axiosInstanceWithToken.get(`${orderApi}/ether`);

      const etherOrders = data?.availableOrders || [];

      const driverCoordsFormatted = { lat: latitude, lon: longitude };

      // Add new fields to order - distanceToOrder, startToFinish, prevDriverCoords, polylinePositions
      const ordersWithDistance = await Promise.all(
        etherOrders.map(async (item) => {
          const existedEtherOrder = prevEtherOrders.find((etherItem) => etherItem.id === item.id) || null;

          // if order exist return this order
          if (existedEtherOrder) {
            const { latitude: prevLatitude, longitude: prevLongitude } = existedEtherOrder.prevDriverCoords;
            distanceToLastCoord = calculateDistance(latitude, longitude, prevLatitude, prevLongitude);

            // if distance not big, not update ether distance
            if (typeof distanceToLastCoord === "number" && distanceToLastCoord < maxRefetchDistance) {
              return existedEtherOrder;
            }

            // recalculate distance to order
            const combinedData = [driverCoordsFormatted, ...existedEtherOrder.destinationPoints];
            const { firstDistance } = await calculateDistanceForEther({ combinedData });

            return {
              ...existedEtherOrder,
              distanceToOrder: firstDistance,
              prevDriverCoords: driverCoords,
            };
          }

          // add new ether order to list
          const combinedData = [driverCoordsFormatted, ...item.destinationPoints];
          const { firstDistance, sumOfRemainingDistances, formattedCoordinates } =
            await calculateDistanceForEther({ combinedData });

          return {
            ...item,
            distanceToOrder: firstDistance,
            startToFinish: sumOfRemainingDistances,
            prevDriverCoords: driverCoords,
            polylinePositions: formattedCoordinates,
          };
        }),
      );


      localStorage.setItem("prevEtherOrders", JSON.stringify(ordersWithDistance));

      dispatch(setEtherOrders(ordersWithDistance));
      const filteredAndSortedOrders = getFilteredEtherOrders(getState());

      dispatch(setEtherOrderStatistics(data.statistics));
      dispatch(setTotalCountOrders(filteredAndSortedOrders.length));
      dispatch(isFetchingEtherOrders(false));
    } catch (error) {
      dispatch(isFetchingEtherOrders(false));
      console.log("Error fetchEtherOrders:", error);
    }
  };
};
