import { memo, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, ButtonVariant } from "src/shared/ui/Button/button";
import { Modal, ModalPosition } from "src/shared/ui/Modal";

import instructionImage from "src/images/instruction-orders.png";

import styles from "./styles.module.scss";

const EtherOrdersModal = () => {
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const userHasReadInstructions = localStorage.getItem("userHasReadInstructions");

    if (!userHasReadInstructions) {
      setOpenModal(true);
    }
  }, []);

  const closeModal = () => {
    setOpenModal(false);
    localStorage.setItem("userHasReadInstructions", true);
  };

  return (
    <Modal isOpen={openModal} onClose={() => setOpenModal(false)} position={ModalPosition.BOTTOM}>
      <div className={styles.manual}>
        <img alt="InstructionModal Orders" src={instructionImage} />
        <p>{t("swipe_right_or_left_to_accept_order")}</p>
        <Button fullWidth onClick={closeModal} variant={ButtonVariant.secondary}>
          {t("ok")}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(EtherOrdersModal);
