import React, { useState } from "react";
import CustomDialog from "../CustomDialog";
import CurrentCar from "./CurrentCar";
import { t } from "i18next";
import FeedbackMessage from "../FeedbackMessage";
import AddNewCar from "./AddNewCar";

const expressCarStepLabels = {
  0: "do_you_work_on_this_car",
  1: "specify_the_details_your_vehicle",
};

const ExpressCar = (props) => {
  const {
    cars,
    classes,
    brands,
    colors,
    models,
    getBrands,
    getClasses,
    getColors,
    getAvtos,
    getModels,
    setDefaultExpressCar,
    disableExpressCarWindow,
  } = props;

  const [step, setStep] = useState(0);

  const isCenterPosition = expressCarStepLabels[step] === expressCarStepLabels[0] ? "center" : "";

  const getStepComponent = () => {
    switch (step) {
      case 0:
        return (
          <CurrentCar
            brands={brands}
            cars={cars}
            classes={classes}
            colors={colors}
            getAvtos={getAvtos}
            getBrands={getBrands}
            getClasses={getClasses}
            getColors={getColors}
            getModels={getModels}
            models={models}
            setStep={setStep}
          />
        );
      case 1:
        return (
          <AddNewCar
            brands={brands}
            cars={cars}
            classes={classes}
            colors={colors}
            getAvtos={getAvtos}
            getBrands={getBrands}
            getClasses={getClasses}
            getColors={getColors}
            getModels={getModels}
            models={models}
            setDefaultExpressCar={setDefaultExpressCar}
            setStep={setStep}
          />
        );
      default:
        return (
          <FeedbackMessage
            disableExpressCarWindow={disableExpressCarWindow}
            subtitle={t("you_can_go_back_to_work")}
            title={t("thank_you_for_your_reply")}
          />
        );
    }
  };

  return (
    <CustomDialog
      content={getStepComponent()}
      fullScreen
      headerOptions={{
        headerTitle: t(expressCarStepLabels[step]),
        showHeaderBackIcon: false,
      }}
      open={true}
    />
  );
};

export default ExpressCar;
