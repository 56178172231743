import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../../axios.config";
import { orderApi } from "../../../config";

export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_ITEM = "GET_HISTORY_ITEM";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const getHistory = createAsyncThunk(
  "driver/getHistory",
  async (isCount = 50, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${orderApi}/driver/history/?skip=0&take=${isCount}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: GET_HISTORY,
          payload: response?.data.data,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getHistoryItem = createAsyncThunk(
  "driver/getHistoryItem",
  async (orderId, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${orderApi}/driver/history/${orderId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: GET_HISTORY_ITEM,
          payload: response?.data.data,
        });
        return response?.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setOrderId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_ID,
      payload: id,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};
