import React from "react";
import styles from "./styles.module.scss";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";
import ReturnButton from "src/shared/ui/Buttons/ReturnButton";
import CustomMap from "src/components/CustomMap";
import SearchComponent from "./components/SearchComponent";
import SuggestionList from "./components/SuggestionList";

const MainManualCoordsView = ({
  onSubmit,
  onReturnButton,
  wrapClassName,
  mapProps,
  address,
  onInputChange,
  addressList,
  showAddressList,
  onClickItemInList,
  mapWrapPops,
  onClear,
}) => {
  return (
    <div className={`${styles.mainManualCoordsView} ${wrapClassName}`}>
      <div className={styles.returnButtonWrap}>
        <MobileTopBarHeight />
        <ReturnButton onClick={onReturnButton} />
      </div>
      <div className={styles.mapWrap} {...mapWrapPops}>
        <CustomMap {...mapProps} />
      </div>
      <div className={styles.manualCoordsSearchComponent}>
        {showAddressList && <SuggestionList itemList={addressList} onClickItemInList={onClickItemInList} />}
        <SearchComponent
          customSearchComponentWrap={styles.customSearchComponentWrap}
          onChange={onInputChange}
          onClear={onClear}
          onSubmit={onSubmit}
          value={address}
        />
      </div>
    </div>
  );
};

export default MainManualCoordsView;
