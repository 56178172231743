import { useNavigate, useLocation } from "react-router-dom";

function useClearUrlParams() {
  const navigate = useNavigate();
  const location = useLocation();

  const clearUrlParams = () => {
    navigate(location.pathname);
  };

  return clearUrlParams;
}

export default useClearUrlParams;
