import {
  INCOMING_ORDER_INFO,
  ACCEPT_INCOMING_ORDER,
  DECLINE_INCOMING_ORDER,
  CURRENT_ORDER_INFO,
  REMOVE_ORDER_REQUEST,
  PAID_ORDER,
  SEND_ORDER_POINT,
  ORDER_STATUS,
  RESET_EXIT_ORDER,
  ERROR_MSG,
  CLEAR_ERROR,
  NO_ORDER,
  CLEAR_ORDER,
  UPDATE_PRICE,
  CURRENT_ORDER_DISPLAY_ERROR_STATUS,
  CURRENT_ORDER_ERROR_INFO,
  MODAL_DECLINED_INCOMING_ORDER,
  CLOSE_MODAL_DECLINED_INCOMING_ORDER,
  ACCEPT_ORDER_LOADING,
} from "./order.actions.js";

const initialState = {
  order: {},
  incomingOrderStatus: false,
  acceptOrder: false,
  acceptOrderLoading: false,
  closeOrder: false,
  currentOrderStatus: false,
  orderPos: {},
  liveOrder: false,
  orderIdForPay: "", //  ?
  canceledOrder: false,
  errorStatus: false,
  errorMsg: "",
  noOrder: false,
  statusIdNotCorrect: false,
  isErrorOrder: false,
  missedOrder: false,
  driverCancelOrder: false,
  rejectedBy: null,
};

function order(state = initialState, action) {
  switch (action.type) {
    case INCOMING_ORDER_INFO:
      return {
        ...initialState,
        incomingOrderStatus: true,
        order: action.payload,
      }; ///*closeOrder
    case CURRENT_ORDER_ERROR_INFO:
      return {
        ...state,
        isErrorOrder: true,
      };
    case ACCEPT_ORDER_LOADING:
      return {
        ...state,
        acceptOrderLoading: action.payload,
      };
    case ACCEPT_INCOMING_ORDER:
      return {
        ...state,
        incomingOrderStatus: false,
        acceptOrder: true,
        liveOrder: true,
      };

    case DECLINE_INCOMING_ORDER:
      return {
        ...state,
        incomingOrderStatus: false,
        closeOrder: true,
        acceptOrder: false,
        currentOrderStatus: false,
        orderPos: {},
        liveOrder: false,
        driverCancelOrder: true,
      };
    case MODAL_DECLINED_INCOMING_ORDER:
      return {
        ...state,
        missedOrder: true,
        incomingOrderStatus: false,
        closeOrder: true,
        acceptOrder: false,
        currentOrderStatus: false,
        orderPos: {},
        liveOrder: false,
        driverCancelOrder: false,
      };
    case CLOSE_MODAL_DECLINED_INCOMING_ORDER:
      return {
        ...state,
        missedOrder: false,
        incomingOrderStatus: false,
        driverCancelOrder: false,
      };
    case PAID_ORDER:
      return {
        ...state,
        noOrder: false,
        incomingOrderStatus: false,
        closeOrder: false,
        acceptOrder: false,
        currentOrderStatus: false,
        orderPos: {},
        liveOrder: false,
      };
    case CURRENT_ORDER_INFO:
      return {
        ...state,
        order: action.payload,
        currentOrderStatus: true,
        /*acceptOrder: true,*/ liveOrder: true,
      };
    case CURRENT_ORDER_DISPLAY_ERROR_STATUS:
      return {
        ...state,
        statusIdNotCorrect: action.payload,
      };
    case REMOVE_ORDER_REQUEST:
      return {
        ...state,
        noOrder: false,
        incomingOrderStatus: action.payload,
      };
    case SEND_ORDER_POINT:
      return { ...state, orderPos: action.payload };
    case ORDER_STATUS:
      return {
        ...state,
        noOrder: false,
        canceledOrder: true,
        liveOrder: false,
        incomingOrderStatus: false,
        orderPos: {},
        acceptOrder: false,
        currentOrderStatus: false,
        rejectedBy: action.payload,
      };
    case RESET_EXIT_ORDER:
      return {
        ...state,
        canceledOrder: false,
        closeOrder: false,
        noOrder: false,
      };
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true };
    case CLEAR_ERROR:
      return { ...state, errorMsg: "", errorStatus: false };
    case NO_ORDER:
      return { ...state, noOrder: action.payload, incomingOrderStatus: false };
    case CLEAR_ORDER:
      localStorage.removeItem("orderStage");

      return {
        ...state,
        order: {},
        incomingOrderStatus: false,
        missedOrder: false,
        driverCancelOrder: false,
        currentOrderStatus: false,
        closeOrder: false,
        orderPos: {},
        liveOrder: false,
        acceptOrder: false,
        canceledOrder: false,
        errorStatus: false,
        errorMsg: "",
        noOrder: false,
      };
    case UPDATE_PRICE:
      const newOrder = { ...state.order };
      const newPricePaid = state.order.bonusPaid ? action.payload - state.order.bonusPaid : action.payload;

      newOrder.pricePaid = newPricePaid;
      newOrder.modal = true;
      return {
        ...state,
        order: { ...newOrder },
      };
    default:
      return { ...state };
  }
}
export default order;
