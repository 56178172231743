import React from "react";

import useCheckGpsStatus from "./hooks/useCheckGpsStatus";
import SetManualCoordsView from "./components/SetManualCoordsView";

const SetManualCoords = ({ coordsDriver, disabledView }) => {
  // check gps status
  const gpsSignalBlocked = useCheckGpsStatus();

  if (!gpsSignalBlocked || disabledView || !coordsDriver) {
    return null;
  }

  return <SetManualCoordsView coordsDriver={coordsDriver} />;
};

export default SetManualCoords;
