import { combineReducers } from "@reduxjs/toolkit";

import { appReducer } from "src/app/appSlice";
import app from "src/app/app.reducer";
import ccard from "src/components/CreditCard/store/ccard.reducer";
import addcar from "src/pages/AddCar/addcar.reducer";
import bottomMenu from "src/pages/BottomMenu/bottommenu.reducer";
import carInspections from "src/pages/CarDocuments/cardocs.reducer";
import cars from "src/pages/Cars/cars.reducer";
import chkCode from "src/pages/CheckCode/checkcode.reducer";
import dashboardReducer from "src/pages/DashboardPage/store/dashboard.reducer";
import editprofile from "src/pages/EditProfile/editprofile.reducer";
import etherReducer from "src/pages/EtherOrdersPage/store/etherReducer";
import fine from "src/pages/Fine/fine.reducer";
import finishedorder from "src/pages/Finishedorder/finishedorder.reducer";
import forgot from "src/pages/Forgot/forgot.reducer";
import gtransactions from "src/pages/GTransactions/gtransactions.reducer";
import history from "src/pages/History/store/history.reducer";
import historyOrder from "src/pages/HistoryOrder/historyorder.reducer";
import login from "src/pages/LoginPage/login.reducer";
import mainnews from "src/pages/MainNews/store/mainnews.reducer";
import map from "src/pages/Map/map.reducer";
import menu from "src/pages/Menu/menu.reducer";
import modalAction from "src/pages/ModalAction/modalAction.reducer";
import modalAlert from "src/pages/ModalAlert/modalAlert.reducer";
import modalAlertSC from "src/pages/ModalAlertSC/modalAlertSC.reducer";
import modalConfirm from "src/pages/Modals/ModalConfirm/modalConfirm.reducer";
import money from "src/pages/MoneyManagementPage/store/money.reducer";
import timer from "src/pages/Order/components/Timer/timer.reducer";
import order from "src/pages/Order/order.reducer";
import orders from "src/pages/Orders/orders.reducer";
import ProfileReducer from "src/pages/Profile/profile.reducer";
import registration from "src/pages/RegistrationPage/registration.reducer";
import stransactions from "src/pages/STransactions/stransactions.reducer";
// RTK
import { rtkApi } from "src/shared/api/rtkApi";
import invoices from "src/pages/Invoices/invoices.reducer";

const rootReducer = combineReducers({
  app,
  login,
  chkCode,
  orders,
  etherReducer,
  order,
  registration,
  menu,
  ProfileReducer,
  editprofile,
  map,
  cars,
  carInspections,
  history,
  mainnews,
  addcar,
  forgot,
  finishedorder,
  modalConfirm,
  modalAlert,
  historyOrder,
  money,
  gtransactions,
  stransactions,
  modalAlertSC,
  fine,
  ccard,
  timer,
  bottomMenu,
  modalAction,
  dashboardReducer,
  appReducer,
  invoices,

  // RTK
  [rtkApi.reducerPath]: rtkApi.reducer,
});

export default rootReducer;
