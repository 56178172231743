import { extractReputationLevel } from "../helpers/extractReputationLevel";

export const mockedReputationType = "Level0";

export const initialDriverRatingState = [
  {
    id: "3f12bfb3-2729-4975-ab73-cdec69d19586",
    scoreChange: 2,
    reason: "Review",
    ratingRange: [4, 5],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "25b1107f-be53-4303-a2ae-4b1b85af11e3",
    scoreChange: -1,
    reason: "Rating",
    ratingRange: [3],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "845b0df5-3d0a-4e79-ad41-8907cbacdabb",
    scoreChange: 2,
    reason: "Rating",
    ratingRange: [5],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "716b4644-e0c5-4e8f-af26-af5194363a6f",
    scoreChange: -6,
    reason: "Rating",
    ratingRange: [1],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "a79e56dc-7cc3-473a-bd93-ce86f257ef3e",
    scoreChange: 10,
    reason: "SetOfRating",
    ratingRange: [5],
    setOfRating: 2,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "73aac4ce-d656-445a-9c52-718ef3d12e6c",
    scoreChange: -5,
    reason: "Review",
    ratingRange: [1, 2, 3],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "9a8f7332-fc10-4e5c-9208-6eea02138f8a",
    scoreChange: -15,
    reason: "SetOfRating",
    ratingRange: [1, 2, 3],
    setOfRating: 3,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "9cdd2cc3-4b61-4bfc-a351-5890c4eeed56",
    scoreChange: -3,
    reason: "Rating",
    ratingRange: [2],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
  {
    id: "32142b7b-6eeb-4668-adb5-b1011ea149d7",
    scoreChange: 1,
    reason: "Rating",
    ratingRange: [4],
    setOfRating: 0,
    createdAt: "2024-03-05T12:36:24",
    isDeleted: false,
  },
];

export const initialDriverCommissionState = [
  {
    id: "f6863130-31e1-4a4d-9d16-ece28e27b887",
    amountOfOrders: 1,
    commissionBase: 14,
    cityId: "be427f49-844b-469b-a8fc-65eed9ae5479",
  },
  {
    id: "88cb37d3-7495-436c-98d1-d92a116c2944",
    amountOfOrders: 50,
    commissionBase: 13,
    cityId: "be427f49-844b-469b-a8fc-65eed9ae5479",
  },
  {
    id: "2dadb2e7-8f3b-4c17-9d27-a2be62eb1599",
    amountOfOrders: 65,
    commissionBase: 12,
    cityId: "be427f49-844b-469b-a8fc-65eed9ae5479",
  },
  {
    id: "02967ff9-e064-4c29-9cd4-42eebb0d389c",
    amountOfOrders: 85,
    commissionBase: 10,
    cityId: "be427f49-844b-469b-a8fc-65eed9ae5479",
  },
  {
    id: "7680553a-25de-4231-a75e-d0c472cde518",
    amountOfOrders: 100,
    commissionBase: 9,
    cityId: "be427f49-844b-469b-a8fc-65eed9ae5479",
  },
  {
    id: "4476deac-d857-4461-954e-6e2b39da12f6",
    amountOfOrders: 75,
    commissionBase: 11,
    cityId: "be427f49-844b-469b-a8fc-65eed9ae5479",
  },
].sort((a, b) => a.amountOfOrders - b.amountOfOrders);

export const initialReputationRulesState = [
  {
    id: 11,
    orderRadius: 30000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level10",
    score: 100,
    isDeleted: false,
  },
  {
    id: 9,
    orderRadius: 10000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level8",
    score: 80,
    isDeleted: false,
  },
  {
    id: 7,
    orderRadius: 6000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level6",
    score: 60,
    isDeleted: false,
  },
  {
    id: 3,
    orderRadius: 2000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level2",
    score: 20,
    isDeleted: false,
  },
  {
    id: 6,
    orderRadius: 5000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level5",
    score: 50,
    isDeleted: false,
  },
  {
    id: 5,
    orderRadius: 4000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level4",
    score: 40,
    isDeleted: false,
  },
  {
    id: 10,
    orderRadius: 20000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level9",
    score: 90,
    isDeleted: false,
  },
  {
    id: 8,
    orderRadius: 8000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level7",
    score: 70,
    isDeleted: false,
  },
  {
    id: 1,
    orderRadius: 0,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level0",
    score: 100,
    isDeleted: false,
  },
  {
    id: 2,
    orderRadius: 2000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level1",
    score: 10,
    isDeleted: false,
  },
  {
    id: 4,
    orderRadius: 3000,
    fee: 0,
    needToBlock: false,
    blockDays: 0,
    blockStartAt: "2024-03-06T14:25:05.160612",
    reputationType: "Level3",
    score: 30,
    isDeleted: false,
  },
].sort((a, b) => extractReputationLevel(a.reputationType) - extractReputationLevel(b.reputationType));
