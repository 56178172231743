import React from "react";
import styles from "./styles.module.scss";

const SuggestionList = ({ itemList, onClickItemInList = () => null, className = "" }) => {
  if (!itemList || itemList.length === 0) {
    return null;
  }
  return (
    <ul className={`${styles.suggestionList} ${className}`}>
      {itemList.map((item, index) => (
        <li key={`${item.address}_${index}`} onClick={() => onClickItemInList(item)}>
          <h2>{item.address}</h2>
        </li>
      ))}
    </ul>
  );
};

export default SuggestionList;
