import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../axios.config";
import { apinew } from "../../config";

export const GET_FINES = "GET_FINES";
export const FINES_ERROR = "FINES_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const FINES_PAID_ERROR = "FINES_PAID_ERROR";

export const getFines = createAsyncThunk("fines/getFines", async (_, { dispatch, rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const response = await axiosInstance.get(`${apinew}/profile/fines`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (response.status === 200 || response.status === 204) {
      dispatch({
        type: GET_FINES,
        payload: response?.data.data,
      });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const paidFine = (fineId) => {
  const accessToken = localStorage.getItem("accessToken");
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
    axiosInstance
      .post(
        `${apinew}/profile/fines/${fineId}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((response) => {
        if (response?.status === 200 || response?.status === 204) {
          dispatch(getFines());
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          dispatch({
            type: FINES_PAID_ERROR,
            payload: "Нет подключения к интернету!",
          });
        } else {
          dispatch({
            type: FINES_PAID_ERROR,
            payload: error,
          });
        }
      });
  };
};
