import { useCallback, useEffect, useMemo, useState } from "react";

import { t } from "i18next";
import { connect } from "react-redux";

import { getCar, updateCar } from "src/pages/Cars/cars.actions";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Buttons/DefautlButton/button";
import Skeleton from "src/shared/ui/Skeleton";

import { ReactComponent as CheckIcon } from "src/images/check.svg";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import "./orderpref.scss";

const OrderPreferences = (props) => {
  const { prefs, editCarInfo, getCar, editCarId, onCloseModal, updateCar } = props;

  const [selectedPrefs, setSelectedPrefs] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const getCurrentCar = async () => {
      if (editCarId) {
        await getCar(editCarId);
      }
    };

    getCurrentCar();
  }, [editCarId, getCar]);

  useEffect(() => {
    if (editCarInfo?.id) {
      const orderPrefIds = editCarInfo.orderPreferences.map((pref) => pref.id);
      setSelectedPrefs(orderPrefIds);
    }

    return () => setIsDisabled(true);
  }, [editCarInfo]);

  const tryUpdCar = async () => {
    const driverId = localStorage.getItem("driverId");

    const carInfo = {
      id: editCarId,
      brandId: editCarInfo.brandId,
      modelId: editCarInfo.modelId,
      colorId: editCarInfo.colorId,
      manufactureYear: editCarInfo.manufactureYear,
      govNumber: editCarInfo.govNumber,
      orderPreferences: selectedPrefs,
      driverId: driverId,
    };

    await updateCar(carInfo);
    await getCar(editCarId);
    onCloseModal();
  };

  const changePref = useCallback(
    (pref) => {
      const selectedPrefsCopy = [...selectedPrefs];
      const index = selectedPrefsCopy.indexOf(pref.id);

      if (index === -1) {
        selectedPrefsCopy.push(pref.id);
      } else {
        selectedPrefsCopy.splice(index, 1);
      }
      setIsDisabled(false);
      setSelectedPrefs(selectedPrefsCopy);
    },
    [selectedPrefs],
  );

  const prefsList = useMemo(
    () =>
      prefs.map((pref) => {
        if (editCarInfo.id) {
          const isSelected = selectedPrefs.includes(pref.id);

          return (
            <li key={pref.id} onClick={() => changePref(pref)}>
              {t(pref.name)}
              {isSelected && <CheckIcon className="check_icon" />}
            </li>
          );
        }
      }),
    [changePref, editCarInfo.id, prefs, selectedPrefs],
  );

  if (!editCarInfo?.id) {
    return <Skeleton border="28px" height="400px" width="100%" />;
  }

  return (
    <div className="order-pref-container">
      <div className="close-block">
        <CloseIcon className="close-icon" onClick={onCloseModal} />
      </div>
      <h1>{t("categories")}</h1>
      <div className="prefs-block">
        <h2>{t("additional_services")}</h2>
        <ul className="prefs-list">{prefsList}</ul>
      </div>
      <div className="confirm_block">
        <Button
          className={classNames("confirm_btn", { btn_disabled: isDisabled }, [])}
          disabled={isDisabled}
          onClick={tryUpdCar}
        >
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cars: state.cars.avtos,
    prefs: state.addcar.preferences,
    editCarId: state.cars.editCarId,
    editCarInfo: state.cars.editCarInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCar: (editCarId) => dispatch(getCar(editCarId)),
    updateCar: (carInfo) => dispatch(updateCar(carInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPreferences);
