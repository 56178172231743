import { forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ScrollableLayout from "src/shared/layouts/ScrollableLayout";

// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomDialog = (props) => {
  const { headerOptions, bodyOptions, open, fullScreen, content, close } = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      className="dialogWrapper"
      fullScreen={fullScreen}
      keepMounted
      onClose={close}
      open={open}
    >
      <ScrollableLayout bodyOptions={bodyOptions} headerOptions={headerOptions}>
        {content}
      </ScrollableLayout>
    </Dialog>
  );
};

export default CustomDialog;
