import { useState } from "react";

import { t } from "i18next";

import OrderRadius from "src/pages/OrderRadius";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Modal } from "src/shared/ui/Modal";

import OrderPreferences from "../OrderPreferences";

import { ReactComponent as ListIcon } from "src/images/list.svg";

import "./ordercontrols.scss";

const OrderControls = (props) => {
  const { handleBeforeRadiusChange, editCar } = props;

  const [openModal, setOpenModal] = useState(false);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenModal = () => {
    editCar();
    setOpenModal(true);
  };

  return (
    <div className="OrderControlsWrapper">
      <Modal isOpen={openModal} noPadding onClose={onCloseModal}>
        <OrderPreferences onCloseModal={onCloseModal} />
      </Modal>

      <div className={classNames("control-element", {}, ["category-controls"])} onClick={onOpenModal}>
        <ListIcon className="list-icon" />
        <p>{t("categories")}</p>
      </div>
      <div className={classNames("control-element", {}, ["radius-controls"])}>
        <OrderRadius handleBeforeRadiusChange={handleBeforeRadiusChange} />
      </div>
    </div>
  );
};

export default OrderControls;
