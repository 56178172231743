import React from "react";

import { ReactComponent as BackIcon } from "src/images/back.svg";

import styles from "./styles.module.scss";

const ReturnButton = ({ onClick, CustomIcon = null, className, ...otherProps }) => {
  return (
    <button className={`${styles.returnButtonWrap} ${className}`} {...otherProps} onClick={onClick}>
      {CustomIcon ? <CustomIcon /> : <BackIcon className={styles.returnButtonIcon}/>}
    </button>
  );
};

export default ReturnButton;
