import React, { forwardRef } from "react";
import { classNames } from "../../../lib/classNames/classNames";
import "./button.scss";

const ButtonComponent = (props, ref) => {
	const { className, children, ...otherProps } = props;
	return (
		<button
			className={classNames("Button", {}, [className])}
			ref={ref}
			{...otherProps}
		>
			{children}
		</button>
	);
};

export const Button = forwardRef(ButtonComponent);