import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "src/shared/const/languages";
import { LANGUAGE_NAME } from "src/shared/const/localstorage";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/ru";
import "dayjs/locale/sk";
import "dayjs/locale/uk";

const useLanguageManager = () => {
  const [_, i18n] = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem(LANGUAGE_NAME));

  const changeLanguage = useCallback(
    (newLanguage) => {
      const normalizedLanguage = newLanguage?.locale ? newLanguage.locale : newLanguage.toUpperCase();
      localStorage.setItem(LANGUAGE_NAME, normalizedLanguage);
      i18n.changeLanguage(normalizedLanguage);
      setLanguage(newLanguage);
      
      // locale for dayjs
      dayjs.locale(newLanguage);
    },
    [i18n],
  );

  const getCurrentLanguage = useCallback(() => {
    const languageName = localStorage.getItem(LANGUAGE_NAME);
    const currentLanguage = SUPPORTED_LANGUAGES.find(
      (language) => language.locale === languageName?.toUpperCase(),
    );
    setLanguage(currentLanguage);
  }, []);

  return { language, changeLanguage, getCurrentLanguage };
};

export default useLanguageManager;
