import {
  GET_AVTOS,
  SET_CAR_ID,
  DEL_CAR,
  ADD_AVTO,
  GET_CAR,
  SET_EDIT_CAR_ID,
  SHOW_CARS_MODAL,
  SET_CURRENT_CAR_ID,
  SELECTED_CAR_STATUS,
  RESET_EDIT_CAR_STATUS,
  SHOW_MODAL_PREFS,
  SET_NEW_PREFS,
  ERROR_MSG,
  CLEAR_ERROR,
  CLEAR_CARS,
  RESET_SELECTED_CAR,
  SHOW_EXPRESS_CAR_WINDOW,
} from './cars.actions.js'

const initialState = {
  avtos: [],
  carId: '',
  editCarId: '',
  requestTOStatus: false,
  requestTOId: '',
  delCar: false,
  delStatus: false,
  addStatus: false,
  editCarInfo: {},
  editCarStatus: false,
  selectCarStatus: false,
  selectedCar: {},
  selectedCarId: '',
  showModalStatus: false, //true,
  showMPrefs: false,
  newPrefs: [],
  errorStatus: false,
  errorMsg: '',
  showExpressCarWindow: JSON.parse(localStorage.getItem('acceptDriverCar')) ?? false,
}

function cars(state = initialState, action) {
  switch (action.type) {
    case GET_AVTOS:
      return {
        ...state,
        avtos: action.payload,
        delStatus: false,
        addStatus: false,
        editCarStatus: false,
      }
    case SHOW_EXPRESS_CAR_WINDOW:
      return { ...state, showExpressCarWindow: action.payload }
    case SET_CAR_ID:
      return { ...state, carId: action.payload }
    case DEL_CAR:
      return { ...state, delCar: action.payload, delStatus: true }
    case ADD_AVTO:
      return { ...state, addStatus: true }
    case SET_EDIT_CAR_ID:
      return { ...state, editCarId: action.payload }
    case GET_CAR:
      return { ...state, editCarInfo: action.payload, editCarStatus: true }
    case RESET_EDIT_CAR_STATUS:
      return { ...state, editCarStatus: false }
    /*   case UPDATE_CAR:
            return {...state, editCarInfo: action.payload }*/
    case SHOW_CARS_MODAL:
      return { ...state, showModalStatus: action.payload }
    case SET_CURRENT_CAR_ID:
      return { ...state, selectedCarId: action.payload }
    case SELECTED_CAR_STATUS:
      return {
        ...state,
        selectCarStatus: action.payload.status,
        selectedCar: action.payload,
      }
    case SHOW_MODAL_PREFS:
      return { ...state, showMPrefs: action.payload }
    case SET_NEW_PREFS:
      return { ...state, newPrefs: action.payload }
    case ERROR_MSG:
      return { ...state, errorMsg: action.payload, errorStatus: true }
    case CLEAR_ERROR:
      return { ...state, errorMsg: '', errorStatus: false }
    case CLEAR_CARS:
      return {
        ...state,
        avtos: [],
        carId: '',
        editCarId: '',
        requestTOStatus: false,
        requestTOId: '',
        delCar: false,
        delStatus: false,
        addStatus: false,
        editCarInfo: {},
        editCarStatus: false,
        selectCarStatus: false,
        selectedCar: {},
        selectedCarId: '',
        showModalStatus: true,
        showMPrefs: false,
        newPrefs: [],
        errorStatus: false,
        errorMsg: '',
      }
    case RESET_SELECTED_CAR:
      return {
        ...state,
        selectCarStatus: false,
        selectedCar: {},
        selectedCarId: '',
      }
    default:
      return { ...state }
  }
}
export default cars
