import React from "react";
import { CircularProgress } from "@mui/material";
import { ReactComponent as LtName } from "src/images/lt-name.svg";

import styles from "./loader.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";

export const Loader = (props) => {
  const { showMenuSpace, loaderText, size = 75, className } = props;
  return (
    <div className={`${styles.loaderWrap} ${showMenuSpace ? styles.showMenuSpace : ""}`}>
      <div className={styles.circularWrap}>
        <CircularProgress
          className={styles.circularBackground}
          size={size}
          style={{
            color: localStorage.getItem("theme") === "true" ? "#EEEEEE" : "#444348",
          }}
          thickness={3}
          value={100}
          variant="determinate"
        />
        <CircularProgress
          className={styles.circular}
          disableShrink
          size={size}
          thickness={3}
          variant="indeterminate"
        />

        <LtName className={classNames(styles.logoCarma, {}, [className])} />
      </div>
      {loaderText && (
        <div className={styles.loaderText}>
          {loaderText.map((item, index) => (
            <h3 key={`${item}_${index}`}>{item}</h3>
          ))}
        </div>
      )}
    </div>
  );
};

export default Loader;
