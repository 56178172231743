import { etherSortOptions } from "src/shared/constants";

export const EtherOrderActionsType = {
  FETCH_ETHER_ORDERS: "FETCH_ETHER_ORDERS",
  SET_ETHER_ORDERS: "SET_ETHER_ORDERS",
  SET_FILTER_TYPE: "SET_FILTER_TYPE",
  SET_SORT_TYPE: "SET_SORT_TYPE",
  SET_DELETED_ORDER_IDS: "SET_DELETED_ORDER_IDS",
  SET_TOTAL_COUNT_ORDERS: "SET_TOTAL_COUNT_ORDERS",
  SET_IS_NEW_ETHER_ORDERS: "SET_IS_NEW_ETHER_ORDERS",
  SET_DISTANCE_FOR_EACH_POINT: "SET_DISTANCE_FOR_EACH_POINT",
  SET_ETHER_ORDER_STATISTICKS: "SET_ETHER_ORDER_STATISTICKS",
};

const orderEtherFilters = JSON.parse(localStorage.getItem("orderEtherFilters"));

const initialState = {
  etherOrders: [],
  etherOrderStatistics: {},
  loading: false,
  totalCount: 0,
  isNewEtherOrders: false,
  deletedEtherOrderIds: JSON.parse(localStorage.getItem("deletedEtherOrderIds")) || [],
  filterType: orderEtherFilters?.filterType || etherSortOptions.ALL,
  sortType: orderEtherFilters?.sortType || {},
  
};

const updateDeletedOrderIds = (state, newDeletedOrderId) => {
  const updatedDeletedOrderIds = [...state.deletedEtherOrderIds, newDeletedOrderId];
  const limitedDeletedOrderIds = updatedDeletedOrderIds.slice(-60);
  localStorage.setItem("deletedEtherOrderIds", JSON.stringify(limitedDeletedOrderIds));
  return limitedDeletedOrderIds;
};

const etherReducer = (state = initialState, action) => {
  switch (action.type) {
    case EtherOrderActionsType.FETCH_ETHER_ORDERS:
      return {
        ...state,
        loading: action.payload,
      };

    case EtherOrderActionsType.SET_ETHER_ORDERS:
      return {
        ...state,
        etherOrders: action.payload,
      };

    case EtherOrderActionsType.SET_ETHER_ORDER_STATISTICKS:
      return {
        ...state,
        etherOrderStatistics: action.payload,
      };

    case EtherOrderActionsType.SET_FILTER_TYPE:
      return {
        ...state,
        filterType: action.payload,
      };

    case EtherOrderActionsType.SET_SORT_TYPE:
      return {
        ...state,
        sortType: {
          ...state.sortType,
          ...action.payload,
        },
      };

    case EtherOrderActionsType.SET_TOTAL_COUNT_ORDERS:
      return {
        ...state,
        totalCount: action.payload,
      };

    case EtherOrderActionsType.SET_DELETED_ORDER_IDS:
      return {
        ...state,
        deletedEtherOrderIds: updateDeletedOrderIds(state, action.payload),
      };

    case EtherOrderActionsType.SET_IS_NEW_ETHER_ORDERS:
      return {
        ...state,
        isNewEtherOrders: action.payload,
      };



    default:
      return state;
  }
};

export default etherReducer;
