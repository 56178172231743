import { memo } from "react";
import { Trans } from "react-i18next";
import { ReactComponent as Cashback } from "src/images/cashback-v2.svg";
import { ReactComponent as MoneyCard } from "src/images/money-card.svg";
import { ReactComponent as MoneyCash } from "src/images/money-cash.svg";
import { calculateCostPerKm } from "src/services/calculateCostPerKm";
import { getValuteSymbol } from "src/services/getValuteSymbol";
import SortingButton from "../../../EtherOrdersDataControl/components/SortingButton";
import EtherOrderMap from "../EtherOrderMap";
import { paymentOptions } from "src/shared/const/payment";
import styles from "./styles.module.scss";
import HighDemandColor from "src/components/HighDemandColor";

const EtherOrderType = (props) => {
  const {
    paymentTypeId,
    priceEstimated,
    carClass,
    distance,
    compensation,
    currency,
    destinationPoints,
    quoteBonus,
    polylinePositions,
    highDemandColor,
  } = props;

  const showOnlyCompensation = Boolean(compensation && !quoteBonus);
  const currentPaymentMethod = paymentOptions[paymentTypeId];
  const isCashPayment =
    currentPaymentMethod === paymentOptions.Cash || currentPaymentMethod === paymentOptions.CashAndBonus;
  const PaymentIcon = isCashPayment ? MoneyCash : MoneyCard;

  const renderPriceAndBonuses = showOnlyCompensation ? priceEstimated : priceEstimated - quoteBonus;
  const renderCompensationAndBonuses = showOnlyCompensation ? compensation : quoteBonus + compensation;

  const renderPriceInfo = (
    <div className={styles.priceAndType}>
      <SortingButton Icon={PaymentIcon} className={styles.price} size="big">
        <span>{priceEstimated}</span>
        <span>{getValuteSymbol(currency)}</span>
      </SortingButton>
      <SortingButton className={styles.carType} size="big">
        <span>{carClass}</span>
      </SortingButton>
      <div className={styles.etherOrderTypeHightDemandIcon}>
        {highDemandColor && <HighDemandColor highDemandColor={highDemandColor} />}
      </div>
    </div>
  );

  const renderAdditionalPriceInfo = (
    <div className={styles.compensationInfo}>
      {Boolean(quoteBonus || compensation) && (
        <SortingButton Icon={PaymentIcon} className={styles.priceAndCompensation} size="small">
          <span className={styles.allPrice}>
            {priceEstimated}
            {getValuteSymbol(currency)}
          </span>

          <span className={styles.divider} />

          <span className={styles.compensation}>
            <Cashback className={styles.icon} />
            {renderCompensationAndBonuses}
            {getValuteSymbol(currency)}
          </span>
        </SortingButton>
      )}

      <p className={styles.priceForKm}>
        <Trans
          i18nKey="price_for_km"
          values={{
            cost: calculateCostPerKm(distance, priceEstimated),
            currency: getValuteSymbol(currency),
          }}
        />
      </p>
    </div>
  );

  return (
    <div className={styles.etherOrderType}>
      <div className={styles.container}>
        {renderPriceInfo}
        {renderAdditionalPriceInfo}
      </div>
      <EtherOrderMap destinationPoints={destinationPoints} polylinePositions={polylinePositions} />
    </div>
  );
};

export default memo(EtherOrderType);
