// Split a large array with point coordinates into segments

export const splitPolylinePositions = (polylinePositions) => {
	if (!polylinePositions.length) return [];

	const firstSegment = polylinePositions.slice(0, 3);
	const middleIndex = Math.floor(polylinePositions.length / 2);
	const middleSegment = polylinePositions.slice(
		middleIndex - 1,
		middleIndex + 2,
	);
	const lastSegment = polylinePositions.slice(-3);

	const allSegments = [...firstSegment, ...middleSegment, ...lastSegment];

	return allSegments;
};
