import React, { useEffect } from "react";
import { CircularProgressBar } from "src/shared/ui/CircularProgressBar";
import { t } from "i18next";
import { FaChevronLeft } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { Button } from "src/shared/ui/Button/button";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import MobileTopBarHeight from "src/shared/ui/MobileTopBarHeight";

const FeedbackMessage = (props) => {
  const { title, subtitle, disableExpressCarWindow } = props;

  const navigate = useNavigate();

  const goToPage = async (page) => {
    await disableExpressCarWindow();
    navigate(`/${page}`);
  };

  useEffect(() => {
    return () => localStorage.setItem("acceptDriverCar", true);
  }, [disableExpressCarWindow]);

  return (
    <div className={styles.mainInfo}>
      <MobileTopBarHeight />
      <div className={styles.backIcon} onClick={() => goToPage("orders")}>
        <FaChevronLeft className={styles.arrowleftIcon} />
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.check}>
          <CircularProgressBar finishStep={true} stepValue="2" valuePercent={100} />
        </div>
        <div className={styles.infoFinish}>
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
        </div>
      </div>
      <div className={styles.appBtn}>
        <Button fullWidth onClick={() => goToPage("orders")}>
          {t("in_app")}
        </Button>
      </div>
      <div className={styles.help} onClick={() => goToPage("contactus")}>
        <div>
          <FiPhone className={styles.phoneIcon} />
        </div>
        <p>{t("customer_service")}</p>
      </div>
    </div>
  );
};

export default FeedbackMessage;
