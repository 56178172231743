import { useLayoutEffect, useState } from "react";
import { defaultStatusBarHeight } from "src/shared/constants";

/* Get heigh mobile top status bar */
const MobileTopBarHeight = () => {
  const [statusBarHeight, setStatusBarHeight] = useState(null);

  useLayoutEffect(() => {
    const statusBarHeight = localStorage.getItem("statusBarHeight") || defaultStatusBarHeight;
    setStatusBarHeight(statusBarHeight);
  }, []);

  if (!statusBarHeight) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        paddingTop: `${statusBarHeight}px`,
      }}
    />
  );
};

export default MobileTopBarHeight;
