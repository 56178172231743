import { axiosInstanceWithToken } from "src/axios.config";
import { apiGeocode } from "src/config";
import { getLanguageCountry } from "src/shared/constants";

// {"addressQuery":"прп","userLocation":{"lat":50.368835,"lon":30.459211},"autocomplete":true,"language":"uk","country":"UA"}
export const fetchSuggestions = async ({ addressQuery, userLocation, autocomplete, languageName }) => {
  let requestData = {
    addressQuery,
    userLocation,
    autocomplete,
    language: getLanguageCountry(languageName),
  };

  if (languageName) {
    requestData = { ...requestData, country: languageName };
  }

  try {
    const response = await axiosInstanceWithToken.post(apiGeocode, requestData);

    return response.data;
  } catch (error) {
    console.log("Error fetching suggestions:", error);
    return []; // Return an empty array or handle the error as needed
  }
};
